<mat-dialog-content class="mat-typography">
    <div class="card mat-elevation-z8 mt-3 listing-container">
        <table class="listing-table" mat-table [dataSource]="dataSource" multiTemplateDataRows >
    
            <ng-container matColumnDef="date">
                <th class="table-header-date" mat-header-cell *matHeaderCellDef>Date</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'Date'"> 
                    <div class="cell-wrapper">
                      {{element.date}} 
                    </div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="format">
                <th class="table-header-format" mat-header-cell *matHeaderCellDef>Format</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'Format'"> 
                    <div class="cell-wrapper" >
                        {{format.refName}} 
                    </div>
                </td>
            </ng-container>
            
            <ng-container matColumnDef="device">
                <th class="table-header-device" mat-header-cell *matHeaderCellDef>Device</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'Device'"> 
                    <div class="cell-wrapper">
                        {{device.refName}}
                    </div>
                </td>
            </ng-container>
            
            <ng-container matColumnDef="channel">
                <th class="table-header-channel" mat-header-cell *matHeaderCellDef>Channel</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'Channel'"> 
                    <div class="cell-wrapper" *ngIf="channel.refName !== 'UL'; else isUL">
                        {{channel.refName}}
                    </div>
                    <ng-template #isUL>
                        <div class="cell-wrapper" >
                            UL 
                          </div>
                    </ng-template>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="dailyBreakdown">
                <th class="table-header-daily-break-down" mat-header-cell *matHeaderCellDef>Daily Breakdown</th>
                <td mat-cell *matCellDef="let element" [attr.data-column-name]="'Daily Breakdown'"> 
                    <div class="cell-wrapper">
                      {{element.tmRequestInventory}} 
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row class="inventory-row" *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="back-btn" mat-raised-button [mat-dialog-close]="true">Back</button>
</mat-dialog-actions>
