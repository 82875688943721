import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { filter } from 'rxjs';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ims';
  showNavbar = true;

  constructor(
    private readonly iconLibrary: FaIconLibrary,
    private router: Router,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {
      this.showNavbar = !this.router.url.includes('login');
    });
    this.iconLibrary.addIconPacks(fas, far, fab);
    this.dateAdapter.setLocale('en-GB');
  }
}
