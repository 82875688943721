import * as moment from 'moment';

export function formatDate(date: Date, format: string): string {
    return moment(date).format(format);
}

export function stringToDate(date: string, format: string): Date {
    // console.log("%s with %s format in stringToDate", date, format , moment(date, format).toDate())
    return moment(date, format).toDate();
}

export function parseDate(event: Event): Date | undefined {
    const dateString = (event.target as HTMLInputElement).value;
    const parts = dateString.split("/");
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // months are 0-based in JavaScript
      const year = parseInt(parts[2], 10);
      
      if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
        return new Date(year, month, day);
      }
    }
    return undefined
}