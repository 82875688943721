<div class="confirm-dialog-container">
    <h2 mat-dialog-title>Confirmation</h2>
    <mat-dialog-content>
        <p *ngIf="warningMessage">{{warningMessage}}</p>
        <p *ngIf="canSubmit">{{message}}</p>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-stroked-button mat-dialog-close>Cancel</button>
        <button mat-flat-button color="primary" (click)="confirm()" *ngIf="canSubmit">Confirm</button>
    </mat-dialog-actions>
</div>