import { FormControl, FormGroup, FormGroupDirective, NgForm, ValidationErrors } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { formatDate } from "src/util/dateUtil";

export class InventoryControlErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

export function InventoryControlDateValidation(formGroup: FormGroup): ValidationErrors | null {
    const startDateControl = formGroup.get('startDate');
    const endDateControl = formGroup.get('endDate');
    
    if (!startDateControl || !endDateControl) {
        return null;
    }

    const startDate = formatDate(startDateControl?.value, 'YYYY-MM-DD');
    const endDate = formatDate(endDateControl?.value, 'YYYY-MM-DD');

    if (startDate && endDate && startDate > endDate) {
        formGroup.get('startDate')?.setErrors({ invalidDateRange: true });
        return { invalidDateRange: true };
    }

    formGroup.get('startDate')?.setErrors(null);
    return null;
}