<div class="container">
    <form [formGroup]="form" class="form">
        <div class="card">
            <h3>Inventory Control</h3>
            <mat-divider></mat-divider>
            <div class="card-body">
                <div class="form-field-container">
                    <label>Date</label>
                    <div class="booking-date-container">
                        <div>
                            <label>From</label>
                            <mat-form-field color="primary">
                                <input matInput [matDatepicker]="startDatePicker" formControlName="startDate"
                                    (focus)="startDatePicker.open()" [errorStateMatcher]="matcher">

                                <mat-datepicker #startDatePicker></mat-datepicker>

                                <mat-error *ngIf="form.get('startDate')?.hasError('required')">
                                    Booking Start Date is required
                                </mat-error>

                                <mat-error *ngIf="form.get('startDate')?.hasError('invalidDateRange')">
                                    Booking Start Date should be before Booking End Date
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div>
                            <label>To</label>
                            <mat-form-field color="primary">
                                <input matInput [min]="form.get('startDate')?.value" [matDatepicker]="endDatePicker"
                                    formControlName="endDate" (focus)="endDatePicker.open()"
                                    [errorStateMatcher]="matcher">

                                <mat-datepicker #endDatePicker></mat-datepicker>

                                <mat-error *ngIf="form.get('endDate')?.hasError('required')">
                                    Booking End Date is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="form-field-container half-width">
                    <label>Channel</label>
                    <mat-form-field appearance="fill" hideRequiredMarker class="channel-container">
                        <mat-select formControlName="channel" [errorStateMatcher]="matcher">
                            <mat-option *ngFor="let option of channelOptions" [value]="option.refId">
                                {{ option.refName }}
                            </mat-option>
                        </mat-select>

                        <mat-error *ngIf="form.get('channel')?.hasError('required')">
                            Channel is required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="device-container">
                    <label>Device</label>
                    <section formArrayName="devices">
                        <div class="checkbox-grid">
                            <mat-checkbox disableRipple color="primary"
                                *ngFor="let option of deviceOptions; let i = index" [formControlName]="i">
                                {{option.refName}}
                            </mat-checkbox>
                        </div>

                        <mat-error *ngIf="noDeviceSelected && deviceOptionsTouched">
                            Please select at least one device.
                        </mat-error>
                    </section>
                </div>

                <div class="format-container">
                    <label>Format</label>
                    <section formArrayName="formats">
                        <div class="checkbox-grid">
                            <mat-checkbox disableRipple color="primary"
                                *ngFor="let option of formatOptions; let i = index" [formControlName]="i">
                                {{option.refName}}
                            </mat-checkbox>
                        </div>

                        <mat-error *ngIf="noFormatSelected && formatOptionsTouched">
                            Please select at least one format.
                        </mat-error>
                    </section>
                </div>

                <div class="search-button-container">
                    <button mat-raised-button color="primary" disableRipple class="search-button"
                        (click)="searchInventorySummary()"
                        [disabled]="searchButtonDisabled()">
                        <ng-container *ngIf="searchLoading">
                            <mat-spinner diameter="20"></mat-spinner>
                        </ng-container>

                        <ng-container *ngIf="!searchLoading">
                            Search
                        </ng-container>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="inventory-result-container" *ngIf="searchCompleted">
    <mat-tab-group animationDuration="0ms" mat-stretch-tabs="false" mat-align-tabs="start"
        [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChange($event)" class="tab">

        <mat-tab *ngFor="let tab of tabs" [label]="upperFirstLetterString(tab)">
            <div class="card-body" [formGroup]="summaryForm">
                <table mat-table [dataSource]="summaryFormArray.controls" formArrayName="summaryFormArray">
                    <ng-container *ngFor="let column of displayColumns" [matColumnDef]="column">
                        <ng-container *ngIf="column === 'date' || column === 'total';else platformSummary">
                            <th mat-header-cell *matHeaderCellDef>{{ upperFirstLetterString(column) }}</th>
                        </ng-container>
                        <ng-template #platformSummary>
                            <th mat-header-cell *matHeaderCellDef>{{ (column) }}</th>
                        </ng-template>
                        <td mat-cell *matCellDef="let summary; let i = index" [formGroupName]="i">
                            <ng-container *ngIf="column === 'date'">
                                <span class="no-wrap">{{ summary.get('date')?.value }}</span>
                            </ng-container>
                            <ng-container *ngIf="column !== 'date' && column !== 'total'">
                                <mat-form-field appearance="fill" class="inventory-result-form-field"
                                    [formGroupName]="column">
                                    <input matInput type="text" [formControlName]="activeTab"
                                        [value]="summary.get(column).get(activeTab).value"
                                        [readonly]="activeTab !== 'forecast' || summary.get('date')?.value == 'Total' " (input)="onInput($event, i, column)"
                                        (blur)="onBlur($event, i, column)"
                                        [ngClass]="{'red-text': isNegativeRemainingInventory(summary.get(column).get(activeTab).value)}">
                                </mat-form-field>
                            </ng-container>

                            <ng-container *ngIf="column === 'total'" [formGroupName]="column">
                                {{ summary.get(column).get(activeTab).value | number }}
                            </ng-container>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
                </table>

                <div class="action-button-container" *ngIf="activeTab == 'forecast'">
                    <button mat-stroked-button (click)="resetSummaryForm()">Reset</button>
                    <button mat-flat-button color="primary" class="update-button"
                        (click)="updateDailyInventorySummary()" [disabled]="updateButtonDisabled()">Update</button>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>