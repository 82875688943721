import { FormArray, FormControl, FormGroup, FormGroupDirective, NgForm, ValidationErrors } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { BookingDetailsDto } from "@ims-shared/dto/booking.dto";
import { BookingDetailRecord } from "./create-detail-dialog/create-detail-dialog.component";
import { UserRole } from "@ims-shared/enum/user-role";

export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export function bookingDateValidation(formGroup: FormGroup): ValidationErrors | null {
  const startDateControl = formGroup.get('bookingStartDate') || formGroup.get('startDate');
  const endDateControl = formGroup.get('bookingEndDate') || formGroup.get('endDate');

  if (!startDateControl || !endDateControl || !startDateControl.value || !endDateControl.value) {
    return null; // Not enough data to validate
  }

  const startDate = new Date(startDateControl.value);
  const endDate = new Date(endDateControl.value);

  // Validate date range
  if (startDate > endDate) {
    startDateControl.setErrors({ invalidDateRange: true });
    return { invalidDateRange: true }; // Return immediately on error
  } else {
    startDateControl.setErrors(null); // Clear previous errors if valid
  }

  // Check for overlaps with other phases
  const phases: FormGroup[] = formGroup.parent?.controls as FormGroup[];
  if (phases && phases.length > 1) {
    const currentPhaseIndex = phases.indexOf(formGroup);
    for (let index = 0; index < phases.length; index++) {
      if (index !== currentPhaseIndex) {
        const phase = phases[index];
        const phaseStartDate = new Date(phase.get('startDate')?.value);
        const phaseEndDate = new Date(phase.get('endDate')?.value);

        // Check for overlap
        if (startDate <= phaseEndDate && endDate >= phaseStartDate) {
          startDateControl.setErrors({ overlap: true });
          return { overlap: true }; // Return immediately on overlap error
        }
      }
    }
  }

  // If no errors found, clear errors
  startDateControl.setErrors(null);
  return null; // No errors
}

export function bookingDetailRecordToDto(phase: any): BookingDetailsDto[] {
  return phase.bookings.map((detail: BookingDetailRecord) => {
    return {
      bookingDetailId: detail.bookingDetailId,
      phase: detail.phase,
      channelId: detail.channel.refId,
      deviceId: detail.device.refId,
      formatId: detail.format.refId,
      tmRequestInventory: detail.tmRequestInventory,
      startDate: phase.startDate,
      endDate: phase.endDate,
      excludeWeekend: phase.excludeWeekend,
      excludePublicHoliday: phase.excludePublicHoliday
    }
  });
}

export function getBookingDetailValidationWarningMessage(role: UserRole, isTm: boolean, sufficientDailyInventory: boolean, sufficientTotalInventory: boolean, remainInventory?: number) {
  let warningMessage = "";
  let shortWarningMessage = "";
  let canSubmit = true;

  if (!sufficientTotalInventory && sufficientDailyInventory) {
    canSubmit = false;
  }

  if (sufficientTotalInventory && !sufficientDailyInventory) {
    shortWarningMessage = "Insufficient daily inventory";
    warningMessage = "Insufficient daily inventory for the selected campaign period. Your campaign may experience under-delivery.";

  } else if (!sufficientTotalInventory && !sufficientDailyInventory) {
    if (role === UserRole.SALES && isTm) {
      canSubmit = false;
      warningMessage = "Insufficient total inventory. Please adjust dates or requesting inventory.";
    } else {
      warningMessage = "Insufficient total inventory for the selected campaign period. Your campaign may experience under-delivery.";
    }
    shortWarningMessage = remainInventory ? `${remainInventory} available` : "Insufficient inventory";
  }

  return { canSubmit, warningMessage, shortWarningMessage };
}