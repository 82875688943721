import { PlacementService } from './../_service/placement.service';
import { Channel } from '@ims-shared/enum/channel';
import { ReferenceDto } from '@ims-shared/dto/reference.dto';
import { AfterViewInit, Component, ElementRef, HostListener, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { mergeMap, of, forkJoin, BehaviorSubject, tap, Subject, takeUntil, catchError, combineLatest, debounceTime, Observable, map } from 'rxjs';
import { bookingDateValidation, CustomErrorStateMatcher } from '../booking/booking.util';
import { BookingType } from '@ims-shared/enum/booking-type';
import { MatSelectChange } from '@angular/material/select';
import { environment } from 'src/environments/environment';
import { ForecastShowHideBoolean, InventoryService } from '../_service/inventory.service';
import { formatNumberToStringWithThousandsSepar } from 'src/util/numUtil';
import { DeviceDetails, InventoryResult, QueryInventoryDto } from '@ims-shared/dto/inventory.dto'
import { DeviceType } from '@ims-shared/enum/device-type';
import { AdSize } from '@ims-shared/enum/ad-size';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ApiService } from '../_service/api.services';
import { BookingService } from '../_service/booking.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Placement, PlacementMap } from '@ims-shared/dto/placements.dto';
import { formatDate } from 'src/util/dateUtil';


export interface PlacementKeyValue {
  key: string;
  placement: Placement | undefined;
}

export interface FormPlacementValue {
  [key : string]: Placement
}

const CHECKBOX_IN_FIRST_ROW = 3;
const CHECKBOX_IN_OTHERS_ROW = 4;

const UL_CHANNELS = [Channel.UL, Channel.SKYPOST, Channel.EZONE]
const IET_CHANNELS = [Channel.IET, Channel.TOPICK, Channel.IMONEY, Channel.PS]
@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css'],
})
export class InventoryComponent implements OnInit{
  userTeam: string = '';
  form = this.fb.group({
    bookingStartDate: new FormControl(new Date(), [Validators.required]),
    bookingEndDate: new FormControl(new Date(), [Validators.required]),
    excludeWeekend: new FormControl(false),
    excludeHoliday: new FormControl(false),
    adSize: new FormControl(),
    placement: new FormControl({}, Validators.required)
  }, { validators: [bookingDateValidation as ValidatorFn] })

  bookingDateLimit = {
    minStart: new Date(),
    minEnd: new Date(),
  }

  isClickedBefore!: boolean
  isChildTouched!: boolean

  devicesArray !: ReferenceDto[]
  adSizeList !: ReferenceDto[];
  channelRefs!: ReferenceDto[];
  platformsDetails!: DeviceDetails;
  adSizePlacementMap?: PlacementMap
  AdSize = AdSize

  inventory_result_rows_rowspan !: number
  no_inventory_results ?: number
  inventoryResult !: InventoryResult[]

  private destroy$ = new Subject<void>();
  
  matcher = new CustomErrorStateMatcher();

  showHideControl!: ForecastShowHideBoolean

  // search result part 
  search_result_breakpoint !: number
  total_num ?: number
  mobile_inventory_num ?: number
  desktop_num ?: number
  
  @ViewChildren('errorLabel') 
  matErrors!: QueryList<ElementRef>;

  constructor(
    private authService: AuthService,
    private placementService: PlacementService,
    private inventoryService: InventoryService,
    private fb: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    private bookingService: BookingService
  ) {
    this.authService.getUserInfo().subscribe((user) => {
      this.userTeam = user.team;
    })
    this.inventory_result_rows_rowspan = 1 
  }

  isMobile: boolean = window.innerWidth < 600;

  @HostListener('window:resize', ['$event'])
  onMobile(event: Event) {
    this.isMobile = window.innerWidth < 600;
  }
  
  ngOnInit(): void {
    this.initiateFormAndSubscribe() 
    this.observeBreakpointChanges();
    this.initiateDeviceChannelCheckBoxes();

    this.form!.addValidators([this.validatorPlacementValueIsEmpty()]);
    this.form!.updateValueAndValidity();
    
    this.search_result_breakpoint = (window.innerWidth <= 685) ? 1: 7;
    console.log("search_result_breakpoint: ", this.search_result_breakpoint)
  }

  ngAfterViewInit() {
    this.matErrors.changes.subscribe(() => {
      this.exe_search_scrollTop();
    });
  }

  initiateFormAndSubscribe(){
    this.inventoryService.resetShowHideControl();// reset err msg
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear() ;
    const day = today.getDate();
    this.form.get("bookingStartDate")!.setValue(new Date(year, month, day, 0, 0))
    this.form.get("bookingEndDate")!.setValue(new Date(year, month, day, 23, 59))
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      console.log("form value: ", value)
      this.updatebookingDateLimit(this.form.get("bookingStartDate")!.value, this.form.get("bookingEndDate")!.value)
      
    });

    this.inventoryService._showHideControl$.subscribe(control => {
      this.showHideControl = control
    });
  }

  initiateDeviceChannelCheckBoxes(){
    const adSizePlacementList$ = this.placementService.getPlacements();
    const channels$ = this.inventoryService.getDeviceChannelsMap();
    const references$ = this.bookingService.findAllReferences();

    forkJoin([adSizePlacementList$, channels$, references$]).subscribe(([adSizePlacements, channels, references]) => {
      this.adSizeList = references.filter(reference => reference.refType === "format")
      this.devicesArray = references.filter(reference => reference.refType === "device")
      this.channelRefs = references.filter(reference => reference.refType === "channel")
      const splashRef = this.adSizeList.filter(reference => reference.refName === AdSize.SPLASH)[0]
      this.form.get("adSize")!.setValue(splashRef)
      this.adSizePlacementMap = adSizePlacements
      // console.log(`adSizePlacementMap: ${JSON.stringify(this.adSizePlacementMap)}`)
      this.platformsDetails = channels;
    })
  }

  onEndDateChange(event: MatDatepickerInputEvent<Date>) {
    const endDate = this.form.get("bookingEndDate")!.value
    if (endDate instanceof Date){
      const month = endDate!.getMonth();
      const year = endDate!.getFullYear() ;
      const day = endDate!.getDate();
      this.form.get("bookingEndDate")!.setValue(new Date(year, month, day, 23, 59))
    }
  }

  onResize(event: Event) {
    const target = event.target as Window;
    this.search_result_breakpoint = (target.innerWidth <= 685) ? 1 : 7;
    if (this.no_inventory_results) {
      this.inventory_result_rows_rowspan = Math.ceil(this.no_inventory_results/3) + 1
    }
    console.log("search_result_breakpoint in onResize: ", this.search_result_breakpoint)
    console.log("inventory_result_rows_rowspan /3 + 1 in onResize", this.inventory_result_rows_rowspan)
  }

  updatebookingDateLimit(form_start: string| Date| null, form_end: string| Date| null){
    if (form_start && form_start instanceof Date){
      const today = new Date(form_start);
      this.bookingDateLimit.minEnd = today
    }
    if (form_start && form_end){
      if (form_start > form_end) {
        this.form.get("bookingEndDate")!.setValue(null)
        this.form.updateValueAndValidity();
      }
    }
  }

  onSelectionChange(event: MatSelectChange){
    // console.log("adSizeKey onSelectionChange:", event.value)
    this.form.get("adSize")!.setValue(event.value)
    this.form.get("placement")!.setValue({}) //reset placement on adSize change
    event.source.close() // remove mat select focus for click placement checkbox without click twice when in focus
  }

  validatorPlacementValueIsEmpty(){
    return (formInputGp: AbstractControl): ValidationErrors | null => {
      const control = formInputGp.get("placement");
      if (Object.keys(control?.value || {}).length === 0) {
        return { adSizeIsEmpty: true };
      } 
      return null; 
    };
  }
  
  mergeAdSizeWithChannelsListByChunk(device: string, channels?: string[]) {
    // console.log("channels:", channels, "device", device)
    if (!channels || !device || !this.adSizePlacementMap) return undefined;
    const chunks = [];
    const adSizeKey = this.form.get('adSize')?.value.refName;
    // console.log("adSizeKey:", adSizeKey)
    if (!adSizeKey){
      return undefined
    }
    const adSizeList = this.adSizePlacementMap[adSizeKey];
    // console.log("adSizeList:", adSizeList)
    const chunkWithAdSize = channels.map(channel => {
      const placementResult = this.getPlacement(channel, device, adSizeKey, adSizeList)
      return { [channel] : placementResult };
    });
    // // console.log("mergeAdSizeWithChannelsListByChunk: ", chunkWithAdSize)
    // for (let i = 0; i < chunkWithAdSize.length; i += CHECKBOX_IN_ROW) {
    //   chunks.push(chunkWithAdSize.slice(i, i + CHECKBOX_IN_ROW));
    // }
    // // console.log("chunks: ", chunks)


    chunks.push(chunkWithAdSize.slice(0, CHECKBOX_IN_FIRST_ROW));

    for (let i = CHECKBOX_IN_FIRST_ROW; i < chunkWithAdSize.length; i += CHECKBOX_IN_OTHERS_ROW) {
      chunks.push(chunkWithAdSize.slice(i, i + CHECKBOX_IN_OTHERS_ROW));
    }
    return chunks
  }

  getPlacement(channel: string, device: string, adSizeKey: string, adSizeList: Placement[]): Placement | undefined {
    const placement = adSizeList.find(
      (p: Placement) => p.format.refName.toLowerCase() === adSizeKey.toLowerCase() &&
      p.device.refName.toLowerCase() === device.toLowerCase() &&
      p.channel.refName.toLowerCase() === channel.toLowerCase()
    );
    if (!placement){
      // console.log(`placement: ${JSON.stringify(placement)} with params: { channel: ${channel}, device: ${device}, adSizeKey: ${adSizeKey}}`)
      return undefined
    }
    const ulChannelRef = this.channelRefs.filter(reference => reference.refName === Channel.UL)[0]
    const ietChannelRef = this.channelRefs.filter(reference => reference.refName === Channel.IET)[0]
    const bufferChannelRef = UL_CHANNELS.includes((placement!.channel.refName) as Channel)  ? ulChannelRef: IET_CHANNELS.includes((placement!.channel.refName) as Channel)  ? ietChannelRef : undefined 
    if (!bufferChannelRef){
      // console.log(`bufferChannelRef: ${bufferChannelRef}, placement: ${JSON.stringify(placement)} with params: { channel: ${channel}, device: ${device}, adSizeKey: ${adSizeKey}}`)
      return undefined
    }
    else{
      return {
        ...placement,
        bufferChannelRef: bufferChannelRef
      }
    }
  }

  onClickPlacement(checked: boolean, placement?: Placement){
    this.isClickedBefore = true
    console.log("checked in onClickPlacement: ", checked)
    if (!placement) return 
    let fieldName = "TM" + "_"+ placement!.device.refName + '_' + placement.channel.refName.replaceAll(' ', '_')
    if (checked){
      let lastPlacement: FormPlacementValue| {}| null = this.form.get("placement")!.value
      let addPlacement = { [fieldName]: placement }
      let currentPlacement = {
        ...lastPlacement,
        ...addPlacement
      }
      this.form.get("placement")!.setValue(currentPlacement)
      console.log("currentPlacement: ", this.form.get("placement")!.value)
    }
    else{
      let formValue = this.form.get("placement")?.value;
      if (formValue && fieldName in formValue) {
        delete (formValue as FormPlacementValue)[fieldName];
        this.form.get("placement")?.patchValue(formValue);
        console.log("deleted: ", (formValue as FormPlacementValue)[placement?.id], "current: ", this.form.get("placement")!.value)
      }
    }
  }

  checkPlacementChannelPlacementId(formValue: FormPlacementValue| {} | null | undefined, placementUniqueName: string): boolean{
    // console.log("formValue in checkPlacementChannelPlacementId: ",formValue)
    
    if (typeof placementUniqueName === 'string' && formValue && typeof formValue === 'object') {
      let placementUniqueNameWithBookingType = "TM_" + placementUniqueName
      if (Object.keys(formValue).length > 0) {
        const placementValue = (formValue as FormPlacementValue)[placementUniqueNameWithBookingType];
        return placementValue !== undefined;
      }
    }
    return false;
  }
  
  objectToString(placement: Placement| undefined){
    return placement ? placement.toString() : ""
  }

  formatNumberWithCommas(num: number| undefined): string{
    return num !== undefined? formatNumberToStringWithThousandsSepar(num): ""
  }

  exe_search_scrollTop() {
    setTimeout(() => {
      const errorElement = this.matErrors.find(error => error.nativeElement !== null);
      if (errorElement) {
        errorElement.nativeElement.parentElement.scrollIntoView({ behavior: "smooth", block: "center"});
      }
    });
  }

  collectErrors(control: AbstractControl): any | null {
    if (control instanceof FormGroup) {
      return Object.entries(control.controls).reduce((acc, [key, childControl]) => {
        const childErrors = this.collectErrors(childControl);
        if (childErrors) {
          acc[key] = childErrors;
        }
        return acc;
      }, {} as any);
    } else {
      return control.errors;
    }
  }

  exe_search() {
    if (this.form.invalid) {
      this.exe_search_scrollTop();
      // const formErrors = this.collectErrors(this.form);
      // console.log('Form Errors:', formErrors);
      this.isClickedBefore = true 
      this.isChildTouched = true
      this.inventoryService.updateShowHideControl({
        total_not_sufficient_text: true,
        mobile_inventory_not_sufficient_text: true,
        desktop_inventory_not_sufficient_text: true
      })
      this.total_num = undefined
      this.mobile_inventory_num = undefined
      this.desktop_num = undefined
      this.inventoryResult = []
      return
    } 

    this.inventoryService.updateShowHideControl({
      isplatformCheckBoxTouched: true,
    })
    this.isClickedBefore = true 
    this.isChildTouched = true
    
    Object.keys(this.form.controls).map(field => {
      const control = this.form.get(field);
      control?.markAsTouched({ onlySelf: true });
    });

    this.form.updateValueAndValidity() //validate the form again for error labels scrolltop
    const formData = this.form.value
    if(this.form.valid && formData.adSize && formData.bookingStartDate && formData.bookingEndDate ){
      this.inventoryService.toggleForecastBtn('disable');
      this.inventoryService.updateShowHideControl({
        total_inventory_err: false,
        mobile_inventory_err: false,
        desktop_inventory_err: false,
        total_not_sufficient_text: false,
        mobile_inventory_not_sufficient_text: false,
        desktop_inventory_not_sufficient_text: false,
        total_loading: true,
        mobile_inventory_loading: true,
        desktop_loading: true
      })
      this.inventoryService.updateIsRunning({
        total: true,
        mobile: true,
        desktop: true
      })
      this.total_num = undefined
      this.mobile_inventory_num = undefined
      this.desktop_num = undefined
      

      console.log('postInventoryResult body:', formData);
      const placementsList: Placement[] = this.convertDictToList((formData.placement as FormPlacementValue));
      const requestBody: QueryInventoryDto = {
        adSize: formData.adSize,
        startDate: formatDate(formData.bookingStartDate, 'YYYY-MM-DD'),
        endDate: formatDate(formData.bookingEndDate, 'YYYY-MM-DD'),
        excludeWeekend: formData.excludeWeekend ? formData.excludeWeekend : false,
        excludePublicHoliday: formData.excludeHoliday ? formData.excludeHoliday : false,
        placements: placementsList
      }
      this.inventoryService.postInventoryResult(requestBody)
      .pipe(takeUntil(this.destroy$)) // cancel pending request onDestroy
      .subscribe({
        next:(res: InventoryResult[]) => {
          try{
            console.log("res", res)
            this.inventoryResult = res
            let len = res.length
            if (len > 0){
              this.no_inventory_results = len
              console.log("search_result_breakpoint", this.search_result_breakpoint)
              this.cal_inventory_result_rows_rowspan()
              console.log("inventory_result_rows_rowspan in exe_search(): ", this.inventory_result_rows_rowspan)
              this.total_num = this.total_num ?? 0;
              this.mobile_inventory_num = this.mobile_inventory_num ?? 0;
              this.desktop_num = this.desktop_num ?? 0;
              res.map(item => {
                this.total_num = this.total_num as number + item.inventoryCount
                if (item.device.refName.toLowerCase().includes("desktop")) {
                  this.desktop_num = this.desktop_num as number + item.inventoryCount
                }
                else{
                  this.mobile_inventory_num = this.mobile_inventory_num as number + item.inventoryCount
                }
              })
            }
            else{
              this.inventoryService.updateShowHideControl({
                total_loading: false,
                mobile_inventory_loading: false,
                desktop_loading: false,
              })
              this.no_inventory_results = 1
              this.total_num = 0
              this.mobile_inventory_num = 0
              this.desktop_num = 0
            }
          }
          catch (error) {
            console.error('Error in getInventoryResult next condition of forecast exe_cal:', error);
            this.inventoryService.updateShowHideControl({
              total_loading: false,
              total_inventory_err: true,
              mobile_inventory_loading: false,
              mobile_inventory_err: true,
              desktop_loading: false,
              desktop_inventory_err: true
            })
            this.no_inventory_results = 1
          }
        },
        error: (error) => {
          console.error('Error in getForecastImpression of forecast exe_cal:', error);
          this.inventoryService.updateShowHideControl({
            total_loading: false,
            total_inventory_err: true,
            mobile_inventory_loading: false,
            mobile_inventory_err: true,
            desktop_loading: false,
            desktop_inventory_err: true,
            showInventoryResultCard: false
          })
          this.inventoryService.updateIsRunning({
            total: false,
            mobile: false,
            desktop: false
          })
          this.inventoryResult = []
          this.inventoryService.toggleForecastBtn('enable');
          this.inventory_result_rows_rowspan = 1 
        },complete: () => {
          this.inventoryService.updateIsRunning({
            total: false,
            mobile: false,
            desktop: false
          })
          this.inventoryService.toggleForecastBtn('enable');
          this.inventoryService.updateShowHideControl({
            total_loading: false,
            mobile_inventory_loading: false,
            desktop_loading: false,
            showInventoryResultCard: true
          })
          console.log(`total_num: ${this.total_num}, mobile_inventory_num: ${this.mobile_inventory_num}, desktop_num: ${this.desktop_num}`)
        }
      })
    } else {
      console.error("Error in given Form Data before starting postInventoryResult!!! ")
      this.inventoryService.updateShowHideControl({
        total_loading: false,
        total_inventory_err: true,
        mobile_inventory_loading: false,
        mobile_inventory_err: true,
        desktop_loading: false,
        desktop_inventory_err: true
      })
      this.inventoryService.updateIsRunning({
        total: false,
        mobile: false,
        desktop: false
      })
      this.inventoryService.toggleForecastBtn('enable');
    }
  }

  isInnerWidth530(){
    return window.innerWidth <= 530
  }

  convertDictToList(data: { [key: string]: any }): Placement[] {
    return Object.keys(data).map(key => ({
      name: data[key].name,
      id: data[key].id,
      format: data[key].format,
      device: data[key].device,
      channel: data[key].channel,
      bufferChannelRef: data[key].bufferChannelRef
    }));
  }

  observeBreakpointChanges() {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe(result => {
      if (result.matches) {
        this.cal_inventory_result_rows_rowspan()
      }
    });
  }

  cal_inventory_result_rows_rowspan() {
    if(!this.no_inventory_results){
      this.inventory_result_rows_rowspan = 1 
      return 
    }
    this.inventory_result_rows_rowspan = Math.ceil(this.no_inventory_results/3)  + 1
    if (this.inventory_result_rows_rowspan > 3){
      this.inventory_result_rows_rowspan += 1
    }
  }
}
