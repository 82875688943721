<div class="table-container">
    <mat-dialog-content>
        <h3>Targeting Setting</h3>
        <mat-divider></mat-divider>
        <table mat-table [dataSource]="dataSource" class="custom-table">
            <ng-container matColumnDef="audienceTargeting">
                <th mat-header-cell *matHeaderCellDef>Audience Targeting </th>
                <td mat-cell *matCellDef="let element"> {{ element.audienceTargeting }} </td>
            </ng-container>

            <ng-container matColumnDef="contentTargeting">
                <th mat-header-cell *matHeaderCellDef>Content Targeting </th>
                <td mat-cell *matCellDef="let element"> {{ element.contentTargeting }} </td>
            </ng-container>

            <ng-container matColumnDef="keyword">
                <th mat-header-cell *matHeaderCellDef>Keywords</th>
                <td mat-cell *matCellDef="let element"> {{ element.keyword }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </mat-dialog-content>
</div>

<div class="dialog-action-container">
    <mat-dialog-actions align="end">
        <button mat-stroked-button mat-dialog-close>Back</button>
    </mat-dialog-actions>
</div>