import { BookingStatus } from "@ims-shared/enum/booking-status";

export function setFormattedBookingStatus(formattedString: string): BookingStatus | undefined {
    const formattedToEnumMapping: { [key: string]: BookingStatus } = {
        'Pending TM Response': BookingStatus.PENDING_TM_RESPONSE,
        'Pending TM Response Confirmation': BookingStatus.PENDING_TM_RESPONSE_CONFIRMATION,
        'Pending Approval': BookingStatus.PENDING_APPROVAL,
        'Approved': BookingStatus.APPROVED,
        'Cancelled': BookingStatus.CANCELLED,
        'Closed': BookingStatus.CLOSED,
        'Error in GAM': BookingStatus.ERROR_GAM
    };

    return formattedToEnumMapping[formattedString];
}