import { Directive, ElementRef, HostListener, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNumberUpTo2DecimalFormatter]'
})
export class NumberUpTo2DecimalDirective {

  constructor(private el: ElementRef, @Self() private ngControl: NgControl) {
    console.log("onInputChange directive");
  }

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    console.log("onInputChange directive");
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value;

    // Remove all non-numeric characters except the decimal point
    value = value.replace(/[^0-9.]/g, '');

    // Apply regex to ensure up to two decimal places
    const regex = /^(\d+\.?\d{0,2})/;
    const match = value.match(regex);
    value = match ? match[0] : '';

    value = value.substring(0, 6);

    // Update the input field only if the value has changed to prevent cursor issues
    if (inputElement.value !== value) {
      inputElement.value = value;
      this.ngControl.control?.setValue(value, { emitEvent: true });
      event.preventDefault(); // Prevent the cursor from moving unexpectedly
    }
  }
}
