import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceDetails, DailyInventorySummary, InventoryResult, QueryInventoryDto, InventorySummary, UpdateInventoryDto, BookingDetailsRequestInventoryValidationResult } from '@ims-shared/dto/inventory.dto';
import { BehaviorSubject, catchError, map, Observable, throwError } from 'rxjs';
import { ApiService } from '../_service/api.services';
import { BookingDetailsDto } from '@ims-shared/dto/booking.dto';

export interface ForecastShowHideBoolean {
  isplatformCheckBoxTouched: boolean,
  total_loading: boolean,
  total_not_sufficient_text: boolean,
  total_inventory_err: boolean,
  mobile_inventory_loading: boolean,
  mobile_inventory_not_sufficient_text: boolean,
  mobile_inventory_err: boolean,
  desktop_loading: boolean,
  desktop_inventory_not_sufficient_text: boolean,
  desktop_inventory_err: boolean,
  disableForecastBtn: boolean,
  showInventoryResultCard: boolean
}

export interface IsRunning {
  total: boolean,
  mobile: boolean,
  desktop: boolean
}

const DEFAULT_SHOW_HIDE_CONTROL: ForecastShowHideBoolean = {
  isplatformCheckBoxTouched: false,
  total_loading: false,
  total_not_sufficient_text: false,
  total_inventory_err: false,
  mobile_inventory_loading: false,
  mobile_inventory_not_sufficient_text: false,
  mobile_inventory_err: false,
  desktop_loading: false,
  desktop_inventory_not_sufficient_text: false,
  desktop_inventory_err: false,
  disableForecastBtn: false,
  showInventoryResultCard: false
}

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(
    private apiService: ApiService,
  ) { }

  isRunning = new BehaviorSubject<IsRunning>({ total: false, mobile: false, desktop: false });
  _isRunning$ = this.isRunning.asObservable();

  updateIsRunning(newValue: Partial<IsRunning>) {
    const currentValue = this.isRunning.getValue();
    const updatedValue = {
      ...currentValue,
      ...newValue
    };
    console.log("updateIsRunning: ", updatedValue)
    this.isRunning.next(updatedValue);
  }

  showHideControl = new BehaviorSubject<ForecastShowHideBoolean>(DEFAULT_SHOW_HIDE_CONTROL);

  _showHideControl$ = this.showHideControl.asObservable();

  updateShowHideControl(newValue: Partial<ForecastShowHideBoolean>) {
    const currentValue = this.showHideControl.getValue();
    const updatedValue = {
      ...currentValue,
      ...newValue
    };
    console.log("updateShowHideControl: ", updatedValue)
    this.showHideControl.next(updatedValue);
  }

  resetShowHideControl() {
    this.updateShowHideControl(DEFAULT_SHOW_HIDE_CONTROL);
  }

  toggleForecastBtn(option: string) {
    switch (option) {
      case "enable":
        if (!this.isRunning.value.total && !this.isRunning.value.mobile && !this.isRunning.value.desktop) {
          this.updateShowHideControl({
            disableForecastBtn: false
          })
        }
        break;
      case "disable":
        this.updateShowHideControl({
          disableForecastBtn: true
        })
        break;
      default:
        break;
    }
  }

  postInventoryResult(body: QueryInventoryDto): Observable<InventoryResult[]> {
    const requestBody = body;
    const api_path = "/inventory"
    const post_headers = new HttpHeaders();

    return this.apiService.post<InventoryResult[]>(api_path, requestBody, post_headers).pipe(
      map((res) => {
        console.log('postCreateOrUpdateCriteria:', res);
        return res;
      }),
      catchError(error => {
        if (error.error.message) {
          const serverErrorMessage = error.error.message;
          return throwError(() => serverErrorMessage);
        } else {
          return throwError(() => 'An unexpected error occurred. Please try again.');
        }
      })
    )
  }

  getDeviceChannelsMap(): Observable<DeviceDetails> {
    const api_path = "/inventory/deviceChannelsMap"

    return this.apiService.get<DeviceDetails>(api_path).pipe(
      map((res) => {
        return res;
      }),
      catchError(error => {
        if (error.error.message) {
          const serverErrorMessage = error.error.message;
          return throwError(() => serverErrorMessage);
        } else {
          return throwError(() => 'An unexpected error occurred. Please try again.');
        }
      })
    )
  }


  getInventorySummary(channelId: number, deviceIds: number[], formatIds: number[], startDate: string, endDate: string): Observable<DailyInventorySummary[]> {
    const header = new HttpHeaders();
    let queryParams = new HttpParams();

    queryParams = queryParams.set('channelId', channelId.toString());
    queryParams = queryParams.set('startDate', startDate);
    queryParams = queryParams.set('endDate', endDate);

    // Append each formatId individually
    formatIds.forEach(id => {
      queryParams = queryParams.append('formatIds', id.toString());
    });

    // Append each deviceId individually
    deviceIds.forEach(id => {
      queryParams = queryParams.append('deviceIds', id.toString());
    });

    return this.apiService.get<DailyInventorySummary[]>('/inventory/summary', header, queryParams).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('Error occurred in getInventorySummary():', error);
        return throwError(() => 'Failed to get inventory summary. Please try again.');
      })
    )
  }

  updateInventory(body: UpdateInventoryDto) {
    return this.apiService.put('/inventory', body).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('Error occurred in updateInventory():', error);
        return throwError(() => 'Failed to update inventory. Please try again.');
      })
    )
  }

  validateBookingDetailsRequestInventory(bookingDetails: BookingDetailsDto[]): Observable<BookingDetailsRequestInventoryValidationResult[]> {
    const body = { bookingDetails };
    return this.apiService.post<BookingDetailsRequestInventoryValidationResult[]>('/inventory/validate', body).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('Error occurred in validate():', error);
        return throwError(() => 'Failed to validate inventory. Please try again.');
      })
    )
  }
}
