import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ReferenceDto } from '@ims-shared/dto/reference.dto';
import { InventoryControlDateValidation, InventoryControlErrorStateMatcher } from './inventory-control.util';
import { BookingService } from '../_service/booking.service';
import { MatTableDataSource } from '@angular/material/table';
import { InventoryService } from '../_service/inventory.service';
import { DailyInventorySummary, InventorySummary, UpdateInventoryDto, UpdateInventoryItem } from '@ims-shared/dto/inventory.dto';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { InventorySummaryType } from '@ims-shared/enum/inventory-summary-type';
import { AuthService } from '../auth/auth.service';
import { UserRole } from '@ims-shared/enum/user-role';
import { upperFirstLetterString } from 'src/util/stringUtil';
import { formatDate } from 'src/util/dateUtil';
@Component({
  selector: 'app-inventory-control',
  templateUrl: './inventory-control.component.html',
  styleUrls: ['./inventory-control.component.css']
})
export class InventoryControlComponent implements OnInit {
  form!: FormGroup;
  summaryForm!: FormGroup;
  matcher = new InventoryControlErrorStateMatcher();
  today!: Date;
  channelOptions!: ReferenceDto[];
  deviceOptions!: ReferenceDto[];
  formatOptions!: ReferenceDto[];
  searchLoading = false;
  searchCompleted = false;
  tabs: InventorySummaryType[] = Object.values(InventorySummaryType);
  activeTab: InventorySummaryType = this.tabs[0];
  selectedTabIndex = 0;
  displayColumns!: string[];
  dataSource: MatTableDataSource<DailyInventorySummary> = new MatTableDataSource<DailyInventorySummary>([]);
  initialInventorySummaryValues: any;
  updatingDate: boolean = false;

  ngOnInit(): void {
    this.today = new Date();
    this._initializeReferences();
    this._initializeForm();
    this._initializeSummaryForm();
    this.listenToDateChanges();
  }

  constructor(
    private bookingService: BookingService,
    private fb: FormBuilder,
    private inventoryService: InventoryService,
    private cdr: ChangeDetectorRef,
  ) { }

  get devices(): FormArray {
    return this.form.get('devices') as FormArray;
  }

  get formats(): FormArray {
    return this.form.get('formats') as FormArray;
  }

  get noDeviceSelected(): boolean {
    return this.devices.value.every((checked: boolean) => !checked);
  }

  get noFormatSelected(): boolean {
    return this.formats.value.every((checked: boolean) => !checked);
  }

  get deviceOptionsTouched(): boolean {
    return this.devices.controls.some(control => control.touched);
  }

  get formatOptionsTouched(): boolean {
    return this.formats.controls.some(control => control.touched);
  }

  get summaryFormArray(): FormArray {
    return this.summaryForm.get('summaryFormArray') as FormArray;
  }

  private _initializeForm(): void {
    this.form = this.fb.group({
      startDate: [new Date(), [Validators.required]],
      endDate: [this.getLastDateOfMonth(), [Validators.required]],
      channel: ['', [Validators.required]],
      devices: this.fb.array([], [Validators.required]),
      formats: this.fb.array([], [Validators.required]),
    }, { validators: InventoryControlDateValidation as ValidatorFn });
  }

  private _initializeSummaryForm(): void {
    this.summaryForm = this.fb.group({
      summaryFormArray: this.fb.array([])
    })
  }

  private _initializeReferences(): void {
    this.bookingService.findAllReferences().subscribe(references => {
      this.channelOptions = references.filter(reference => reference.refType === 'channel');
      this.deviceOptions = references.filter(reference => reference.refType === 'device');
      this.formatOptions = references.filter(reference => reference.refType === 'format');

      const deviceControls = this.deviceOptions.map(() => new FormControl(false));
      this.form.setControl('devices', this.fb.array(deviceControls));

      const formatControls = this.formatOptions.map(() => new FormControl(false));
      this.form.setControl('formats', this.fb.array(formatControls));

      this.form.patchValue({
        channel: this.channelOptions[0]?.refId,
      });
    });
  }

  private fetchInventorySummary(): void {
    const startDate = formatDate(this.form.get('startDate')?.value, 'YYYY-MM-DD');
    const endDate = formatDate(this.form.get('endDate')?.value, 'YYYY-MM-DD');
    const channelId = this.form.get('channel')?.value;
    const deviceIds = this.getIdsFromFormArray(this.devices, this.deviceOptions);
    const formatIds = this.getIdsFromFormArray(this.formats, this.formatOptions);

    this.inventoryService.getInventorySummary(channelId, deviceIds, formatIds, startDate, endDate).subscribe({
      next: (dailyInventorySummaries: DailyInventorySummary[]) => {
        this.displayColumns = this.getDisplayColumns(dailyInventorySummaries);
        this.parseInventorySummaryToFormArray(dailyInventorySummaries);
        this.initialInventorySummaryValues = this.summaryFormArray.value;
        this.form.markAsPristine(); // Reset form state to pristine after successful fetch
        this.searchLoading = false;
      },
      error: (error) => {
        console.error(error);
        this.searchLoading = false;
      },
      complete: () => {
        this.searchCompleted = true;
      }
    });
  }

  listenToDateChanges(): void {
    const format = 'YYYY-MM-DD';
    this.form.get('endDate')?.valueChanges.subscribe((date: Date) => {
      if (this.updatingDate) {
        return;
      }
      const startDate = formatDate(this.form.get('startDate')?.value, format);
      const endDate = formatDate(this.form.get('endDate')?.value, format);
      if (startDate > endDate) {
        this.updatingDate = true; // Set flag to prevent re-entry
        this.form.get('endDate')?.setValue(null);
        this.cdr.detectChanges(); // Manually trigger change detection
        this.updatingDate = false;  // Reset flag after update
      }
    })
  }

  private getDisplayColumns(dailyInventorySummaries: DailyInventorySummary[]): string[] {
    const columns = new Set<string>();
    dailyInventorySummaries.forEach((dailySummary: DailyInventorySummary) => {
      dailySummary.summaries.forEach((summary: InventorySummary) => {
        const key = `${summary.channel.refName} ${summary.device.refName} - ${summary.format.refName}`;
        columns.add(key);
      })
    })
    return ['date', ...Array.from(columns), 'total'];
  }

  private _createSummaryFormGroup(summary: InventorySummary): FormGroup {
    const { forecast, adjusted, reserved, remaining } = summary;
    // return this.fb.control(summary);
    return this.fb.group({
      channelId: [summary.channel.refId, [Validators.required]],
      deviceId: [summary.device.refId, [Validators.required]],
      formatId: [summary.format.refId, [Validators.required]],
      forecast: [this.formatNumber(forecast), [Validators.required]],
      adjusted: [this.formatNumber(adjusted), [Validators.required]],
      reserved: [this.formatNumber(reserved), [Validators.required]],
      remaining: [this.formatNumber(remaining), [Validators.required]],
    })
  }

  private _createDailyTotalFormGroup(dailyTotal: any): FormGroup {
    return this.fb.group({
      forecast: [dailyTotal.forecast, [Validators.required]],
      adjusted: [dailyTotal.adjusted, [Validators.required]],
      reserved: [dailyTotal.reserved, [Validators.required]],
      remaining: [dailyTotal.remaining, [Validators.required]],
    })
  }

  private parseInventorySummaryToFormArray(dailyInventorySummaries: DailyInventorySummary[]) {
    const summaryFormArray: FormArray = this.fb.array([]);
    dailyInventorySummaries.forEach((dailySummary: DailyInventorySummary) => {
      const summaryFormGroup = this.fb.group({});
      summaryFormGroup.addControl('date', new FormControl(dailySummary.date));
      summaryFormGroup.addControl('total', this._createDailyTotalFormGroup(dailySummary.total));

      dailySummary.summaries.forEach((summary: InventorySummary) => {
        const key = `${summary.channel.refName} ${summary.device.refName} - ${summary.format.refName}`;
        summaryFormGroup.addControl(key, this._createSummaryFormGroup(summary));
      });
      summaryFormArray.push(summaryFormGroup);
    });
    summaryFormArray.push(this.columnTotalFormGroup(dailyInventorySummaries));
    this.summaryForm.setControl('summaryFormArray', summaryFormArray);
  }

  private getLastDateOfMonth(): Date {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  private getIdsFromFormArray(arr: FormArray, options: ReferenceDto[]): number[] {
    return arr.value
      .map((checked: boolean, index: number) => {
        const reference = checked ? options[index] : null;
        return reference ? reference.refId : null;
      })
      .filter((id: number | null) => id !== null);
  }

  private getUpdatedInventories(): UpdateInventoryItem[] {
    const updateInventories: UpdateInventoryItem[] = [];
    const dailySummaries = this.summaryFormArray.controls as FormGroup[];
    dailySummaries.forEach((dailySummary: FormGroup) => {
      const date = dailySummary.get('date')?.value;
      for (const key in dailySummary.value) {
        if (key == 'date' || key == 'total') {
          continue;
        }
        const summaryValue = dailySummary.get(key)?.value;
        const summaryInInitialValues = this.initialInventorySummaryValues.find((initialSummary: any) => initialSummary.date == date)[key];
        const isSummaryChanged = Object.keys(summaryValue).some((key: string) => summaryValue[key] !== summaryInInitialValues[key]);
        const tmTotal = parseInt(summaryValue.forecast.replace(/,/g, ""));
        if (isSummaryChanged) {
          updateInventories.push({
            date: date,
            channelId: summaryValue.channelId,
            deviceId: summaryValue.deviceId,
            formatId: summaryValue.formatId,
            tmTotal: tmTotal
          })
        }
      }
    })
    return updateInventories;
  }

  calculateTotal(control: FormGroup): number {
    const dailySummary = control.value;
    let total = 0;
    for (const key in dailySummary) {
      if (key !== 'date') {
        const summary = dailySummary[key];
        if (summary) {
          total += summary[this.activeTab];
        }
      }
    }
    return total;
  }

  columnTotalFormGroup(dailyInventorySummaries: DailyInventorySummary[]): FormGroup {
    const formGroup = this.fb.group({});
    formGroup.addControl('date', new FormControl('Total'));
    const total = {
      forecast: 0,
      adjusted: 0,
      reserved: 0,
      remaining: 0,
    }

    const map = new Map<string, { adjusted: number, forecast: number, remaining: number, reserved: number }>();
    dailyInventorySummaries.forEach((dailySummary: DailyInventorySummary) => {
      dailySummary.summaries.forEach((summary: InventorySummary) => {
        const key = `${summary.channel.refName} ${summary.device.refName} - ${summary.format.refName}`;
        const columnTotal = map.get(key) || { adjusted: 0, forecast: 0, remaining: 0, reserved: 0 };
        columnTotal.adjusted += summary.adjusted;
        columnTotal.forecast += summary.forecast;
        columnTotal.remaining += summary.remaining;
        columnTotal.reserved += summary.reserved;
        map.set(key, columnTotal);

        total.adjusted += summary.adjusted;
        total.forecast += summary.forecast;
        total.remaining += summary.remaining;
        total.reserved += summary.reserved;
      });

    });

    map.forEach((value, key) => {
      const columnTotal = this.fb.group({
        forecast: [this.formatNumber(value.forecast), [Validators.required]],
        adjusted: [this.formatNumber(value.adjusted), [Validators.required]],
        reserved: [this.formatNumber(value.reserved), [Validators.required]],
        remaining: [this.formatNumber(value.remaining), [Validators.required]],
      });
      formGroup.addControl(key, columnTotal);
    });

    formGroup.addControl('total', new FormGroup({
      forecast: new FormControl(total.forecast),
      adjusted: new FormControl(total.adjusted),
      reserved: new FormControl(total.reserved),
      remaining: new FormControl(total.remaining),
    }))
    return formGroup;
  }

  onTabChange(event: MatTabChangeEvent) {
    this.activeTab = this.tabs[event.index];
  }

  resetSummaryForm() {
    this.summaryFormArray.setValue(this.initialInventorySummaryValues);
  }

  searchInventorySummary(): void {
    const atLeastOneDeviceChecked = this.devices.value.some((checked: boolean) => checked);
    const atLeastOneFormatChecked = this.formats.value.some((checked: boolean) => checked);

    if (this.form.invalid || !atLeastOneDeviceChecked || !atLeastOneFormatChecked) {
      return;
    }

    this.searchLoading = true;
    this.fetchInventorySummary();
  }

  updateDailyInventorySummary() {
    const body: UpdateInventoryDto = { inventories: this.getUpdatedInventories() };
    this.inventoryService.updateInventory(body).subscribe({
      next: (res) => {
        this.fetchInventorySummary();
      },
      error: (error) => {
        console.error(error);
      }
    })
  }

  onInput(event: any, index: number, column: string) {
    const value = event.target.value.replace(/,/g, ""); // Remove commas from input
    const number = Number(value);

    const inventory = this.summaryFormArray.at(index).get(column)?.get(this.activeTab);
    if (isNaN(number)) {
      event.target.value = value;
    } else {
      event.target.value = number.toLocaleString("en-US");
      inventory?.setValue(number, { emitEvent: false });
    }

    this.summaryFormArray.at(index).get(column)?.get(this.activeTab)?.setValue(this.formatNumber(number), { emitEvent: false });
  }

  onBlur(event: any, index: number, column: string) {
    const value = this.summaryFormArray.at(index).get(column)?.get(this.activeTab)?.value;
    const formattedValue = this.formatNumber(value);
    event.target.value = formattedValue;
  }

  formatNumber(value: number): string {
    return value.toLocaleString("en-US");
  }

  upperFirstLetterString(str: string) {
    return upperFirstLetterString(str)
  }

  searchButtonDisabled(): boolean {
    return this.form.invalid || this.noDeviceSelected || this.noFormatSelected || this.form.pristine;
  }

  updateButtonDisabled(): boolean {
    return this.summaryFormArray.invalid || this.summaryFormArray.pristine;
  }

  isNegativeRemainingInventory(value: string): boolean {
    const numValue = Number(value.replace(/,/g, ""));
    return this.activeTab === 'remaining' && numValue < 0;
  }
}
