<div class="container">
    <form [formGroup]="form" class="form">
        <!-- basic settings -->
        <div class="card">
            <h3>Inventory Search</h3>
            <mat-divider></mat-divider>
            <div class="card-body">

                <div class="form-field-container">
                    <label>Booking Date</label>
                    <mat-grid-list cols="1" [rowHeight]="form.get('bookingStartDate')?.hasError('invalidDateRange') ? !isMobile ? '90px': '110px' : '70px'">
                        <mat-grid-tile [rowspan]="isMobile ? 3: 1" [class.bookingDate-block]="isMobile">
                            <label class="date-picker-grid-element grid-tile-label">From</label>
                            <mat-form-field color="primary" class="date-picker-grid-element date-form-field">
                                <input matInput [min]="bookingDateLimit.minStart" [matDatepicker]="bookingStartDatePicker"
                                    formControlName="bookingStartDate" (focus)="bookingStartDatePicker.open()"
                                    [errorStateMatcher]="matcher" [readonly]="true">

                                <mat-datepicker #bookingStartDatePicker></mat-datepicker>

                                <mat-error #errorLabel *ngIf="form.get('bookingStartDate')?.hasError('required')">
                                    Booking Start Date is required
                                </mat-error>
                            </mat-form-field>
                            <label class="date-picker-grid-element grid-tile-label">To</label>
                            <mat-form-field color="primary" class="date-picker-grid-element date-form-field">
                                <input matInput 
                                    [min]="bookingDateLimit.minEnd" 
                                    [matDatepicker]="bookingEndDatePicker"
                                    formControlName="bookingEndDate" 
                                    (focus)="bookingEndDatePicker.open()" 
                                    (dateChange)="onEndDateChange($event)"
                                    [errorStateMatcher]="matcher" 
                                    [readonly]="true">

                                <mat-datepicker #bookingEndDatePicker></mat-datepicker>

                                <mat-error #errorLabel *ngIf="form.get('bookingEndDate')?.hasError('required')">
                                    Booking End Date is required
                                </mat-error>
                            </mat-form-field>
                        </mat-grid-tile>
                    </mat-grid-list>

                    <section class="date-picker-checkbox-container">
                        <mat-checkbox disableRipple color="primary" formControlName="excludeWeekend" class="date-picker-checkbox">Exclude
                            Weekend</mat-checkbox>
                        <mat-checkbox disableRipple color="primary" formControlName="excludeHoliday" class="date-picker-checkbox">Exclude Public
                            Holiday</mat-checkbox>
                    </section>
                </div>

                <div class="form-field-container" *ngIf="form.get('adSize')?.value">
                    <label>Format</label>
                    <mat-form-field class="drop-down-select-field" color="primary">
                        <mat-select class="drop-down-select-field-text" formControlName="adSize" name="adSize" panelClass="select-panel" placeholder="-" (selectionChange)="onSelectionChange($event)">
                            <mat-select-trigger>
                                <span class="drop_down_selected_value">{{ form.get('adSize')!.value!.refName }}</span>
                                <mat-icon class="drop_down_field_arrow">keyboard_arrow_down</mat-icon>
                            </mat-select-trigger>
                            <mat-option *ngFor="let format of adSizeList" [value]="format">
                                {{ format.refName }}
                            </mat-option>
                          </mat-select>
                    </mat-form-field>
                </div>

                <div class="form-field-container">
                    <label>Device and Channel</label>
                    <div class="form-group placement_platform">
                        <table class="table-borderless placement-platform-table">
                            <tbody *ngIf="adSizePlacementMap">
                                <ng-container *ngFor="let device of devicesArray">
                                    <tr>
                                        <th class="channel_title"><label>{{device.refName}}</label></th>
                                    </tr>
                                    <ng-container *ngIf="platformsDetails[device.refName].channels">
                                        <tr *ngFor="let chunks of mergeAdSizeWithChannelsListByChunk(device.refName, platformsDetails[device.refName].channels)">
                                            <td *ngFor="let chunk of chunks">
                                              <ng-container *ngFor="let channel of chunk| keyvalue">
                                                <div class="custom-control custom-checkbox">
                                                    <mat-checkbox disableRipple color="primary" class="placement_option" *ngIf="channel.key !== 'UL' && channel.key !== 'SkyPost'; else isULOrSkypost" [disabled]="!channel.value"
                                                                [id]="device.refName + '_' + channel.key.replaceAll(' ', '_')"
                                                                [name]="device.refName + '_' + channel.key.replaceAll(' ', '_')"
                                                                [checked]="channel?.value?.id != null && form.get('placement')?.value && checkPlacementChannelPlacementId(form.get('placement')?.value, device.refName + '_' + channel.key.replaceAll(' ', '_'))"
                                                                [value]="objectToString(channel.value)"
                                                                (change)="onClickPlacement($event.checked, channel.value)"
                                                                >
                                                                {{ channel.key }}
                                                    </mat-checkbox>
                                                    <ng-template #isULOrSkypost>
                                                        <mat-checkbox disableRipple color="primary" class="placement_option" *ngIf="channel.key === 'UL'; else isSkypost" [disabled]="!channel.value"
                                                                [id]="device.refName + '_' + channel.key.replaceAll(' ', '_')"
                                                                [name]="device.refName + '_' + channel.key.replaceAll(' ', '_')"
                                                                [checked]="channel?.value?.id != null && form.get('placement')?.value && checkPlacementChannelPlacementId(form.get('placement')?.value, device.refName + '_' + channel.key.replaceAll(' ', '_'))"
                                                                [value]="objectToString(channel.value)"
                                                                (change)="onClickPlacement($event.checked, channel.value)"
                                                                >
                                                            U Lifestyle
                                                        </mat-checkbox>
                                                    </ng-template>
                                                    <ng-template #isSkypost>
                                                        <mat-checkbox disableRipple color="primary" class="placement_option" *ngIf="channel.key === 'SkyPost'" [disabled]="!channel.value"
                                                                [id]="device.refName + '_' + channel.key.replaceAll(' ', '_')"
                                                                [name]="device.refName + '_' + channel.key.replaceAll(' ', '_')"
                                                                [value]="objectToString(channel.value)"
                                                                [checked]="channel?.value?.id != null && form.get('placement')?.value && checkPlacementChannelPlacementId(form.get('placement')?.value, device.refName + '_' + channel.key.replaceAll(' ', '_'))"
                                                                (change)="onClickPlacement($event.checked, channel.value)"
                                                                >
                                                                Sky Post
                                                        </mat-checkbox>
                                                    </ng-template>
                                                </div>
                                              </ng-container>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                    <mat-error #errorLabel for="placement" class="error" [class.hidden]="!(isClickedBefore || isChildTouched) || !form.hasError('adSizeIsEmpty')">Please select device(s) / channel(s)</mat-error>
                </div>


                <div class="row-content-center">
                    <button class="button-long-1 forecast-btn" mat-raised-button color="primary" type="button" disableRipple [disabled]="showHideControl.disableForecastBtn"
                    (click)="exe_search();">Search Inventory Availability</button>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- Search Result  -->
<div class="result-container background-transparent">
    <h3 class="result-container-title">Search Results</h3>
    <mat-grid-list [cols]="search_result_breakpoint" rowHeight="415px" (window:resize)="onResize($event)">
        <mat-grid-tile
            class="inventory_result_rows"
            [colspan]="search_result_breakpoint > 5 ? 5: 1"
            [rowspan]="inventory_result_rows_rowspan">
            <ng-container *ngIf="showHideControl.showInventoryResultCard && search_result_breakpoint > 1 && no_inventory_results">
                <mat-card class="row-card" *ngFor="let i of inventoryResult;let num = index;" [class.max-width-of-last-card]="no_inventory_results%2 ==1 && num== no_inventory_results-1">
                    <div class="row-card-format">
                        <p class="ims-grey">Format</p>
                        <h3 class="static_num" [class.static_num_padding]="i.format.refName === AdSize.MEDIUM_RECTANGLE">
                            {{i.format.refName}}
                        </h3>
                    </div>
                    <div class="row-card-device">
                        <p class="ims-grey">Device</p>
                        
                        <h3 class="static_num">
                            {{i.device.refName}}
                        </h3>
                    </div>
                    <div class="row-card-channel">
                        <p class="ims-grey">Channel</p>
                        
                        <h3 class="static_num" *ngIf="i.channel.refName !== 'UL' && i.channel.refName !== 'SkyPost'; else isULOrSkypost">
                            {{i.channel.refName}}
                        </h3>
                        <ng-template #isULOrSkypost>
                            <h3 class="static_num" *ngIf="i.channel.refName == 'UL'; else isSkypost">
                                U Lifestyle
                            </h3>
                        </ng-template>
                        <ng-template #isSkypost>
                            <h3 class="static_num" *ngIf="i.channel.refName === 'SkyPost'">
                                Sky Post
                            </h3>
                        </ng-template>
                    </div>
                    <div class="row-card-num" >
                        <p class="ims-grey">Available Inventory</p>
                        <h3 class="static_num">
                            {{formatNumberWithCommas(i.inventoryCount)}}
                        </h3>
                    </div>
                </mat-card>
            </ng-container>
            <ng-container *ngIf="showHideControl.showInventoryResultCard &&  search_result_breakpoint == 1  && no_inventory_results">
                <div class="table-like">
                    <ng-container *ngFor="let item of inventoryResult; let i = index" >
                      <div class="row" *ngIf="i % 2 === 0">
                        <mat-card class="row-card"  [class.max-width-of-last-card]="no_inventory_results%2 == 1 && i == no_inventory_results-1" >
                            <div class="row-card-format">
                                <p class="ims-grey">Format</p>
                                <h3 class="static_num" [class.static_num_padding]="inventoryResult[i].format.refName === AdSize.MEDIUM_RECTANGLE">
                                    {{inventoryResult[i].format.refName}}
                                </h3>
                            </div>
                            <div class="row-card-device">
                                <p class="ims-grey">Device</p>
                                
                                <h3 class="static_num">
                                    {{inventoryResult[i].device.refName}}
                                </h3>
                            </div>
                            <div class="row-card-channel">
                                <p class="ims-grey">Channel</p>
                                
                                <h3 class="static_num" *ngIf="inventoryResult[i].channel.refName !== 'UL' && inventoryResult[i].channel.refName !== 'SkyPost'; else isULOrSkypost">
                                    {{inventoryResult[i].channel.refName}}
                                </h3>
                                <ng-template #isULOrSkypost>
                                    <h3 class="static_num" *ngIf="inventoryResult[i].channel.refName == 'UL'; else isSkypost">
                                        U Lifestyle
                                    </h3>
                                </ng-template>
                                <ng-template #isSkypost>
                                    <h3 class="static_num" *ngIf="inventoryResult[i].channel.refName === 'SkyPost'">
                                        Sky Post
                                    </h3>
                                </ng-template>
                            </div>
                            <div class="row-card-num" >
                                <p class="ims-grey">Available Inventory</p>
                                <h3 class="static_num">
                                    {{formatNumberWithCommas(inventoryResult[i].inventoryCount)}}
                                </h3>
                            </div>
                        </mat-card>
                        <mat-card class="row-card " *ngIf="inventoryResult[i + 1]" [class.ml-2]="isInnerWidth530()">
                            <div class="row-card-format">
                                <p class="ims-grey">Format</p>
                                <h3 class="static_num" [class.static_num_padding]="inventoryResult[i + 1].format.refName === AdSize.MEDIUM_RECTANGLE">
                                    {{inventoryResult[i + 1].format.refName}}
                                </h3>
                            </div>
                            <div class="row-card-device">
                                <p class="ims-grey">Device</p>
                                
                                <h3 class="static_num">
                                    {{inventoryResult[i + 1].device.refName}}
                                </h3>
                            </div>
                            <div class="row-card-channel">
                                <p class="ims-grey">Channel</p>
                                
                                <h3 class="static_num" *ngIf="inventoryResult[i + 1].channel.refName !== 'UL' && inventoryResult[i + 1].channel.refName !== 'SkyPost'; else isULOrSkypost">
                                    {{inventoryResult[i + 1].channel.refName}}
                                </h3>
                                <ng-template #isULOrSkypost>
                                    <h3 class="static_num" *ngIf="inventoryResult[i + 1].channel.refName == 'UL'; else isSkypost">
                                        U Lifestyle
                                    </h3>
                                </ng-template>
                                <ng-template #isSkypost>
                                    <h3 class="static_num" *ngIf="inventoryResult[i + 1].channel.refName === 'SkyPost'">
                                        Sky Post
                                    </h3>
                                </ng-template>
                            </div>
                            <div class="row-card-num" >
                                <p class="ims-grey">Available Inventory</p>
                                <h3 class="static_num">
                                    {{formatNumberWithCommas(inventoryResult[i + 1].inventoryCount)}}
                                </h3>
                            </div>
                        </mat-card>
                      </div>
                    </ng-container>
                  </div>
            </ng-container>
        </mat-grid-tile>
        <mat-grid-tile
            class="inventory_sum_stat_col background-white"
            [colspan]="search_result_breakpoint > 1 ? 2: 1"
            [rowspan]="1">
            <div id="total_inventory" class="inventory-result">
                <div class="total_statics_card" >
                    <div class="col-statics-card-body">
                        <div [class]="(showHideControl.total_inventory_err || showHideControl.total_not_sufficient_text) ? 'col_static_title_pt_s': showHideControl.showInventoryResultCard && search_result_breakpoint > 1 ? 'col_static_title_pt_l' : 'col_static_title_pt_m'" >
                            <p class="ims-grey static-title">Total Available Inventory</p>
                            <h3 class="card-item-h3">
                                <span id="total_num" class="inventory_num">
                                    <ng-container *ngIf="total_num === undefined && !showHideControl.total_loading ; else showImpressionNum">
                                        <mat-icon>more_horiz</mat-icon>
                                    </ng-container>
                                    <ng-template #showImpressionNum>
                                        
                                        <span class="static_num">{{formatNumberWithCommas(total_num)}}</span>
                                    </ng-template>
                                </span>
                            </h3>
                            <mat-error id="total_not_sufficient_text" class="not_sufficient_text custom-error" *ngIf="showHideControl.total_not_sufficient_text">
                                <!-- 目前未有足夠數據可供使用 -->
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                No sufficient data
                            </mat-error>
                            <div class="text-center my-4 inventory_loading inventory_total_loading" id="total_loading" *ngIf="showHideControl.total_loading">
                                <i class="fa fa-spinner fa-pulse fa-3x ims-blue"></i>
                            </div>
                            <mat-error id="total_inventory_err" class="inventory_err inventory_total_err" *ngIf="showHideControl.total_inventory_err">
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                <!-- 發生錯誤，請稍後重試。 -->
                                Please try later
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>

            <div id="mobile_inventory" class="inventory-result">
                <div class="mx-auto mt-4" >
                    <div class="col-statics-card-body">
                        <div [class]="(showHideControl.mobile_inventory_err || showHideControl.mobile_inventory_not_sufficient_text) ? 'col_static_title_pt_s': 'col_static_title_pt_m'">
                            <p class="ims-grey static-title">Total Available Mobile Inventory: </p>
                            <h3 class="card-item-h3">
                                <span id="mobile_inventory_num" class="inventory_num">
                                    <ng-container *ngIf="mobile_inventory_num === undefined && !showHideControl.mobile_inventory_loading ; else showAvailableImpressionNum">
                                        <mat-icon>more_horiz</mat-icon>
                                    </ng-container>
                                    <ng-template #showAvailableImpressionNum >
                                        
                                        <span class="static_num">{{formatNumberWithCommas(mobile_inventory_num)}}</span>
                                    </ng-template>
                                </span>
                            </h3>
                            <mat-error id="mobile_inventory_not_sufficient_text" class="not_sufficient_text custom-error" *ngIf="showHideControl.mobile_inventory_not_sufficient_text">
                                <!-- 目前未有足夠數據可供使用 -->
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                No sufficient data
                            </mat-error>
                            <div class="text-center my-4 inventory_loading inventory_mobile_inventory_loading" id="mobile_inventory_loading" *ngIf="showHideControl.mobile_inventory_loading">
                                <i class="fa fa-spinner fa-pulse fa-3x ims-blue"></i>
                            </div>
                            <mat-error id="mobile_inventory_err" class="inventory_err inventory_mobile_inventory_err" *ngIf="showHideControl.mobile_inventory_err">
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                <!-- 發生錯誤，請稍後重試。 -->
                                Please try later
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>

            <div id="desktop_inventory" class="inventory-result">
                <div class="mx-auto mt-4" >
                    <div class="col-statics-card-body">
                        <div [class]="(showHideControl.desktop_inventory_err || showHideControl.desktop_inventory_not_sufficient_text) ? 'col_static_title_pt_s': 'col_static_title_pt_m'">
                            <p  class="ims-grey static-title">Total Available Desktop Inventory: </p>
                            <h3 class="card-item-h3">
                                <span id="desktop_num" class="inventory_num">
                                    <ng-container *ngIf="desktop_num === undefined && !showHideControl.desktop_loading ; else showCtrNum">
                                        <mat-icon>more_horiz</mat-icon>
                                    </ng-container>
                                    <ng-template #showCtrNum>
                                        
                                        <span class="static_num">{{formatNumberWithCommas(desktop_num)}}</span>
                                    </ng-template>
                                </span>
                            </h3>
                            <mat-error id="desktop_inventory_not_sufficient_text" class="not_sufficient_text custom-error" *ngIf="showHideControl.desktop_inventory_not_sufficient_text">
                                <!-- 目前未有足夠數據可供使用 -->
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                No sufficient data
                            </mat-error>
                            <div class="text-center my-4 inventory_loading" id="desktop_loading" *ngIf="showHideControl.desktop_loading">
                                <i class="fa fa-spinner fa-pulse fa-3x ims-blue"></i>
                            </div>
                            <mat-error id="desktop_inventory_err" class="inventory_err" *ngIf="showHideControl.desktop_inventory_err">
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                <!-- 發生錯誤，請稍後重試。 -->
                                Please try later
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>
        </mat-grid-tile>
    </mat-grid-list>
</div>

