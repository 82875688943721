import { Component, OnInit } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthService } from '../auth/auth.service';
import { UserRole } from '@ims-shared/enum/user-role';

interface NavItem {
  label: string;
  path: string;
  icon: string;
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  navItems: NavItem[] = [
    { label: 'Booking', path: '/booking/list', icon: 'list_alt'},
    { label: 'Detail', path: '/booking/detail', icon: 'info'},
    { label: 'Inventory', path: '/inventory', icon: 'search'},
    { label: 'Control', path: '/inventory/control', icon: 'edit'},
    { label: 'Configuration', path: '/config', icon: 'settings'}
  ];

  name: string = '';
  role: string| undefined = '';
  team: string = '';
  email: string = '';
  showUserCard = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this._initiateUserRoleAndAccessCheck();
  }

  toggleSidenav() {
    console.log('toggle sidenav');
  }

  toggleUserCard() {
    this.showUserCard = !this.showUserCard;
  }

  logout() {
    this.authService.signOut();
  }

  _initiateUserRoleAndAccessCheck(): void {
    this.authService.getUserInfo().subscribe({
      next:(userInfo) => {
        console.log(userInfo);
        const empty = "N/A";
        this.name = userInfo.name || empty;
        this.role = userInfo.role || empty;
        this.team = userInfo.team || empty;
        this.email = userInfo.email || empty;
      },
      error: (error) => {
        this.role = undefined
        console.error(`error in _initiateUserRoleAndAccessCheck(): ${error}`)
        this.navItems = this.mapAccessGroup()
        
      },
      complete: ()=> {
        this.navItems = this.mapAccessGroup()
      }
    }) 
  }

  mapAccessGroup(): NavItem[] {
    return this.navItems.filter(page => {
      if (this.role === undefined){
        return false
      }
      else if (page.label === "Configuration" && (this.role === UserRole.TM || this.role === UserRole.SALES || this.role === UserRole.SALES_ADMIN)) {
        return false;
      } else if (( page.label === "Control") && (this.role === UserRole.SALES || this.role === UserRole.SALES_ADMIN)) {
        return false;
      } else if ((page.label === "Detail") && (this.role === UserRole.SALES )) {
        return false;
      }
      else {
        return true;
      }
    });
  }
}