import { BookingStatus } from "../enum/booking-status";

export function getDbBookingStatusEnum(): { [key: string]: string } {
    const descriptions: { [key: string]: string } = {
        [BookingStatus.PENDING_TM_RESPONSE]: 'Pending TM Response',
        [BookingStatus.PENDING_TM_RESPONSE_CONFIRMATION]: 'Pending TM Response Confirmation',
        [BookingStatus.PENDING_APPROVAL]: 'Pending Approval',
        [BookingStatus.APPROVED]: 'Approved',
        [BookingStatus.CANCELLED]: 'Cancelled',
        [BookingStatus.CLOSED]: 'Closed'
    };
    return descriptions;
}