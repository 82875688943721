import { AdSize } from './../enum/ad-size';
import { DeviceType } from './../enum/device-type';
import { RequestType } from "../enum/request-type";
import { BookingStatus } from "../enum/booking-status";
import { NullPartial } from "../utils/type-util";
import { ContentTagIdTag } from "./content-tag.dto";
import { Channel } from "./../enum/channel";
import { ReferenceDto } from './reference.dto';
import { AdvertiserDto } from './advertiser.dto';
import { CriteriaDto } from './criteria.dto';

export enum GamOrderErrorStatus {
    ERROR_ORDER = "ERROR_ORDER",
    ERROR_APPROVE = "ERROR_APPROVE"
}

export enum GamLineItemErrorStatus {
    ERROR_APPROVE = "ERROR_APPROVE",
    ERROR_LINE_ITEM = "ERROR_LINE_ITEM",
    ERROR_PREVIEW_LINE_ITEM = "ERROR_PREVIEW_LINE_ITEM",
    ERROR_BOTH = "ERROR_BOTH",
}

interface _BookingDetailsDto {
    bookingDetailId: number;
    phase: number;
    channelId: number;
    deviceId: number,
    formatId: number,
    tmRequestInventory: number;
    startDate: string,
    endDate: string,
    excludeWeekend: boolean;
    excludePublicHoliday: boolean;
}

interface _BookingGamLineItemDetailDto {
    bookingDetailId: number;
    orderId: number;
    previewLineItemId: number,
    lineItemId: number,
    lineItemName: string;
    errorStatus: GamLineItemErrorStatus
}

interface _BookingGamOrderDto {
    bookingId: number;
    advertiserId: number;
    orderId: number,
    orderName: string,
    orderApproved: boolean;
    errorStatus: GamOrderErrorStatus,
    lineItemDetails: BookingGamLineItemDetailDto[]
}

interface _BookingDto {
    id: number;
    bookingName: string;
    advertiser: AdvertiserDto;
    bookingCategoryId: number;
    isTm: boolean;
    remarks: string;
    status: BookingStatus;
    tmBookingObjective: string;
    tmKeyResultImpressions: string;
    tmKeyResultCtr: string;
    tmKeyResultOther: string;
    tmAdditionalInfo: string;
    criteria: CriteriaDto[];
    createdBy: string;
    creatorName: string;
    creatorTeam: string;
    createdDate: Date;
    modifiedBy: string;
    modifiedDate: Date;
    bookingDetails: BookingDetailsDto[];
    keywords: string[];
    contentTags: ContentTagIdTag[];
    requestType: RequestType;
    bookingGamOrder: BookingGamOrderDto;
}

export interface BookingGamLineItemDetailDto extends NullPartial<_BookingGamLineItemDetailDto, "bookingDetailId"> { }
export interface BookingGamOrderDto extends NullPartial<_BookingGamOrderDto, "bookingId" | "advertiserId"> { }
export interface BookingDetailsDto extends NullPartial<_BookingDetailsDto, "bookingDetailId" | "startDate" | "endDate"> { }
export interface BookingDto extends NullPartial<_BookingDto, "id" | "bookingDetails"> { }
export interface CreateBookingDto extends Omit<BookingDto, "id" | "status" | "createdBy" | "creatorName" | "creatorTeam" | "createdDate" | "modifiedBy" | "modifiedDate"> { }
export interface BookingListItemDto extends Pick<BookingDto, "id" | "bookingName" | "advertiser" | "isTm" | "status" | "createdBy" | "creatorName" | "creatorTeam" | "bookingDetails" | "bookingGamOrder"> { }
export interface CreatorListItemDto extends Pick<BookingDto, "creatorName"> { }
export interface BookingNameListItemDto extends Pick<_BookingDto, "bookingName"> { }

export interface BookingListDto {
    bookings: BookingListItemDto[];
    count: number;
}

export interface PageFilterOptionDto {
    isTm?: boolean;
    status?: BookingStatus;
    creatorName?: string;
    startDate?: string;
    endDate?: string;
    searching?: string;
    sorting?: string;
}

export interface PageFilterOptionQueryParamDto {
    isTm?: string;
    status?: BookingStatus;
    creatorName?: string;
    startDate?: string;
    endDate?: string;
    searching?: string;
    targetBu?: string;
    sorting?: string;
}

export type UpdateBookingDto = CreateBookingDto & { id: number };

export interface QueryBookingDetailsBodyDto {
    channel?: ReferenceDto[];
    device?: ReferenceDto[],
    format?: ReferenceDto[],
    bookingStatus?: BookingStatus[];
    bookingStartDate?: string;
    bookingEndDate?: string;
    bookingName ?: string | BookingNameListItemDto;
}

interface _BookingDeatilBookingAttrDto extends Pick<BookingDto, "id" | "bookingName" | "advertiser"  | "status" | "creatorName"> { }
export interface BookingDetailsInquiryDto {
    bookingDetailId: number,
    channel: ReferenceDto;
    device: ReferenceDto,
    format: ReferenceDto,
    requestedInventory?: number;
    booking: _BookingDeatilBookingAttrDto,
    duration?: number,
    startDate: string,
    endDate: string
}

export interface BookingDetailsDailyInquiryDto {
    date: string;
    tmRequestInventory: number;
}