<div class="container" *ngIf="form">
    <form [formGroup]="form" class="form">
        <div class="card">
            <h3>Inventory Estimation Configratuion</h3>
            <mat-divider></mat-divider>
            <mat-tab-group class="tab-headers-row" mat-stretch-tabs="false" mat-align-tabs="start">
                <mat-tab label="Adjustment Percentage">
                    <mat-grid-list class="form-field-container" [cols]="percentage_table_breakpoint" [rowHeight]="tableHeight" (window:resize)="onResize($event)">
                        <mat-grid-tile
                            [colspan]="percentage_table_breakpoint"
                            [rowspan]="percentage_table_breakpoint == 1 ? 2: 1"
                            [class.percentage_table_grid_pl]="percentage_table_breakpoint == 1"
                            >
                            <ng-container *ngFor="let d of devices; let i = index">
                                <table class="percentage_table" *ngIf="adSizeConfigs">
                                    <tr>
                                        <td *ngIf="d.key !== 'desktop'"></td>
                                        <td *ngIf="d.key == 'desktop' && percentage_table_breakpoint == 1"></td>
                                        <td colspan="2" class="table-device-header">{{d.name}}</td>
                                    </tr>
                                    <tr>
                                        <td *ngIf="d.key !== 'desktop'"></td>
                                        <td *ngIf="d.key == 'desktop' && percentage_table_breakpoint == 1"></td>
                                        <td *ngFor="let i of channels" class="text-center">{{i.name}}</td>
                                    </tr>
                                    <tr class="adSize-input-row" *ngFor="let adSize of adSizeConfigs" [formGroupName]="d.key + '_' + adSize.refName">
                                        <td *ngIf="d.key !== 'desktop'" class="adSize-type">
                                            {{adSize.refName}}
                                        </td>
                                        <td *ngIf="d.key == 'desktop' && percentage_table_breakpoint == 1" class="adSize-type">
                                            {{adSize.refName}}
                                        </td>
                                        <td *ngFor="let channel of channels" class="adSize-input-field">
                                            <div class="table-cell-input-field-gp">
                                                <mat-form-field class="percentage-field" [formGroupName]="channel.key">
                                                    <input matInput formControlName="percentage" (change) = "parsePercentageToNumber(d.key + '_' + adSize.refName, channel.key)" appNumberUpTo2DecimalFormatter>
                                                    <mat-error #matError *ngIf="channel.key =='ul' && form.get(d.key + '_' + adSize.refName + '.' + channel.key+ '.percentage')?.errors?.['required'];else minMaxError">This field is required.</mat-error>
                                                    <ng-template #minMaxError>
                                                        <mat-error #matError *ngIf="channel.key =='ul' && (form.get(d.key + '_' + adSize.refName + '.' + channel.key+ '.percentage')?.errors?.['min'] || form.get(d.key + '_' + adSize.refName + '.' + channel.key+ '.percentage')?.errors?.['max']);else validNumber">Please enter a number within 0-100</mat-error>
                                                    </ng-template>
                                                    <ng-template #validNumber>
                                                        <mat-error #matError *ngIf="channel.key =='ul' && form.get(d.key + '_' + adSize.refName + '.' + channel.key+ '.percentage')?.errors?.['pattern'];else ietAnd">Please enter a valid number.</mat-error>
                                                    </ng-template>
                                                    <ng-template #ietAnd>
                                                        <mat-error #matError *ngIf="channel.key !=='ul' && !isNextToFieldError(d.key + '_' + adSize.refName) && form.get(d.key + '_' + adSize.refName + '.' + channel.key+ '.percentage')?.errors?.['required'];else ietAndminMaxError">This field is required.</mat-error>
                                                    </ng-template>
                                                    <ng-template #ietAndminMaxError>
                                                        <mat-error #matError *ngIf="channel.key !=='ul' && !isNextToFieldError(d.key + '_' + adSize.refName) && (form.get(d.key + '_' + adSize.refName + '.' + channel.key+ '.percentage')?.errors?.['min'] || form.get(d.key + '_' + adSize.refName + '.' + channel.key+ '.percentage')?.errors?.['max']);else ietAndvalidNumber">Please enter a number within 0-100</mat-error>
                                                    </ng-template>
                                                    <ng-template #ietAndvalidNumber>
                                                        <mat-error #matError *ngIf="channel.key !=='ul' &&  !isNextToFieldError(d.key + '_' + adSize.refName) && form.get(d.key + '_' + adSize.refName + '.' + channel.key+ '.percentage')?.errors?.['pattern'];">Please enter a valid number.</mat-error>
                                                    </ng-template>
                                                </mat-form-field>
                                                <span class="ml-3">%</span>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </ng-container>
                        </mat-grid-tile>
                    </mat-grid-list>

                </mat-tab>
                <mat-tab label="Extra Adjustment Percentage for Public Holiday">
                    <mat-grid-list class="form-field-container" [cols]="percentage_table_breakpoint" [rowHeight]="tableHeight" (window:resize)="onResize($event)">
                        <mat-grid-tile
                            [colspan]="percentage_table_breakpoint"
                            [rowspan]="percentage_table_breakpoint == 1 ? 2: 1"
                            [class.percentage_table_grid_pl]="percentage_table_breakpoint == 1"
                            >
                            <ng-container *ngFor="let d of devices; let i = index" >
                                <table class="percentage_table" *ngIf="adSizeConfigs">
                                    <tr>
                                        <td *ngIf="d.key !== 'desktop'"></td>
                                        <td *ngIf="d.key == 'desktop' && percentage_table_breakpoint == 1"></td>
                                        <td colspan="2" class="table-device-header">{{d.name}}</td>
                                    </tr>
                                    <tr>
                                        <td *ngIf="d.key !== 'desktop'"></td>
                                        <td *ngIf="d.key == 'desktop' && percentage_table_breakpoint == 1"></td>
                                        <td *ngFor="let i of channels" class="text-center">{{i.name}}</td>
                                    </tr>
                                    <tr class="adSize-input-row" *ngFor="let adSize of adSizeConfigs" [formGroupName]="d.key + '_' + adSize.refName+ '_holiday'">
                                        <td *ngIf="d.key !== 'desktop'" class="adSize-type">
                                            {{adSize.refName}}
                                        </td>
                                        <td *ngIf="d.key == 'desktop' && percentage_table_breakpoint == 1" class="adSize-type">
                                            {{adSize.refName}}
                                        </td>
                                        <td *ngFor="let channel of channels;" class="adSize-input-field">
                                            <div class="table-cell-input-field-gp">
                                                <mat-form-field class="percentage-field" [formGroupName]="channel.key">
                                                    <input matInput formControlName="percentage" appNumberUpTo2DecimalFormatter (change) = "parsePercentageToNumber(d.key + '_' + adSize.refName+ '_holiday', channel.key)">
                                                    <mat-error #matError *ngIf="channel.key=='ul' && form.get(d.key + '_' + adSize.refName + '_holiday' + '.' + channel.key+ '.percentage')?.errors?.['required'];else minMaxError">This field is required.</mat-error>
                                                    <ng-template #minMaxError>
                                                        <mat-error #matError *ngIf="channel.key=='ul' && (form.get(d.key + '_' + adSize.refName + '_holiday' + '.' + channel.key+ '.percentage')?.errors?.['min'] || form.get(d.key + '_' + adSize.refName + '_holiday' + '.' + channel.key+ '.percentage')?.errors?.['max']);else validNumber">Please enter a number within 0-100</mat-error>
                                                    </ng-template>
                                                    <ng-template #validNumber>
                                                        <mat-error #matError *ngIf="channel.key=='ul' && form.get(d.key + '_' + adSize.refName + '_holiday' + '.' + channel.key+ '.percentage')?.errors?.['pattern'];else ietAnd">Please enter a valid number.</mat-error>
                                                    </ng-template>
                                                    <ng-template #ietAnd>
                                                        <mat-error #matError *ngIf="channel.key=='iet' && !isNextToFieldError(d.key + '_' + adSize.refName + '_holiday') && form.get(d.key + '_' + adSize.refName + '_holiday' + '.' + channel.key+ '.percentage')?.errors?.['required'];else ietAndminMaxError">This field is required.</mat-error>
                                                    </ng-template>
                                                    <ng-template #ietAndminMaxError>
                                                        <mat-error #matError *ngIf="channel.key=='iet' && !isNextToFieldError(d.key + '_' + adSize.refName + '_holiday') && (form.get(d.key + '_' + adSize.refName + '_holiday' + '.' + channel.key+ '.percentage')?.errors?.['min'] || form.get(d.key + '_' + adSize.refName + '_holiday' + '.' + channel.key+ '.percentage')?.errors?.['max']);else ietAndvalidNumber">Please enter a number within 0-100</mat-error>
                                                    </ng-template>
                                                    <ng-template #ietAndvalidNumber>
                                                        <mat-error #matError *ngIf="channel.key=='iet' && !isNextToFieldError(d.key + '_' + adSize.refName + '_holiday') && form.get(d.key + '_' + adSize.refName + '_holiday' + '.' + channel.key+ '.percentage')?.errors?.['pattern'];">Please enter a valid number.</mat-error>
                                                    </ng-template>
                                                </mat-form-field>
                                                <span class="ml-3">%</span>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </ng-container>
                        </mat-grid-tile>
                    </mat-grid-list>
                </mat-tab>
                <mat-tab label="Frequency Cap for Splash Ad">
                    <div class="form-field-container">
                        <table class="percentage_table">
                            <tr *ngFor="let f of freq_list">
                                <td>{{f.name}}: </td>
                                <td *ngIf="form.get(f.control+'_time_unit')">
                                    <mat-form-field *ngIf="form.get(f.control+'_time_unit')?.value === 'hour'; else minute" class="input-field freq-input-field" [formGroupName]="f.control">
                                        <input matInput formControlName="impressions" (change)="parseFreqCapToNumber(f.control, form.get(f.control+'_time_unit')?.value, $event)">
                                        <mat-error *ngIf="(getSubFormGroup(f.control)?.invalid)">Please enter a valid integer</mat-error>
                                    </mat-form-field>
                                    <ng-template #minute>
                                        <mat-form-field class="input-field freq-input-field" [formGroupName]="f.control">
                                            <input matInput formControlName="impressions" (change)="parseFreqCapToNumber(f.control, form.get(f.control+'_time_unit')?.value, $event)">
                                            <mat-error *ngIf="(getSubFormGroup(f.control)?.invalid)">Please enter a valid integer</mat-error>
                                        </mat-form-field>
                                    </ng-template> 
                                    <span class="freq_note">
                                        impression(s) per 
                                        <mat-form-field *ngIf ="form.get(f.control+'_time_unit')?.value === 'hour';else Minutes" class="input-field freq-input-field" [formGroupName]="f.control">
                                            <input matInput formControlName="hour" (change)="parseFreqCapTimeUnitToNumber(f.control, form.get(f.control+'_time_unit')?.value, $event)">
                                            <mat-error *ngIf="(getSubFormGroup(f.control)?.invalid)">Please enter a valid integer</mat-error>
                                            
                                        </mat-form-field>
                                        <ng-template #Minutes>
                                            <mat-form-field class="input-field freq-input-field" [formGroupName]="f.control">
                                                <input matInput formControlName="minute" (change)="parseFreqCapTimeUnitToNumber(f.control, form.get(f.control+'_time_unit')?.value, $event)">
                                                <mat-error *ngIf="(getSubFormGroup(f.control)?.invalid)">Please enter a valid integer</mat-error>
                                                
                                            </mat-form-field>
                                        </ng-template>
                                        <mat-select class="freq_note_unit" [formControlName]="f.control+'_time_unit'" panelClass="select-panel" (selectionChange)="onFreqCapTimeUnitChange(f.control, $event)">
                                            <mat-select-trigger>
                                                <span class="filter_cond_selected_value" *ngIf="form.get(f.control+'_time_unit')?.value === 'hour';else Mins">Hour(s)</span>
                                                <ng-template #Mins>
                                                    <span class="filter_cond_selected_value" >Minute(s)</span>
                                                </ng-template>
                                                <mat-icon class="filter_cond_field_arrow" >keyboard_arrow_down</mat-icon>
                                            </mat-select-trigger>
                                            <mat-option *ngFor="let time of freq_time_list" [value]="time" matTooltipPosition="right" >
                                                <span *ngIf = "time === 'hour'; else minutes">
                                                    Hour(s)
                                                </span>
                                                <ng-template #minutes>
                                                    <span>
                                                        Minute(s)
                                                    </span>
                                                </ng-template>
                                            </mat-option>
                                        </mat-select>
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div class="row-content-center form-buttons-padding">
            <button mat-raised-button color="primary" class="form-save-button" 
                [disabled] = "form.pristine || !form.valid"            
                type="submit" disableRipple (click)="save()">
                Save
            </button>
        </div>
    </form>
    
</div>

