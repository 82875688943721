import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialog {
    type: string;
    message: string;
    warningMessage: string;
    canSubmit: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ConfirmDialog>
    ) {
        this.type = data.type;
        this.warningMessage = data.warningMessage;
        this.canSubmit = data.canSubmit;
        
        if (this.type === 'save') {
            this.message = `Are you sure to ${this.type} the booking?`
        }

        switch (this.type) {
            case 'save':
                if (data.bookingId) {
                    this.message = `Are you sure to save the booking?`;
                } else {
                    this.message = `Are you sure to submit the booking?`;
                }
                break;
            case 'submit':
                this.message = `Are you sure to submit the TM response?`;
                break;
            case 'confirm':
                this.message = `Are you sure to confirm the TM response?`;
                break;
            case 'approve':
                this.message = `Are you sure to approve the booking?`;
                break;
            case 'cancelAndCopy':
                this.message = `Are you sure to cancel the original booking and copy existing information to new booking form?`;
                break;
            default:
                this.message = `Are you sure to save the booking?`;
                break;
        }
    }

    confirm() {
        this.dialogRef.close(true);
    }
}