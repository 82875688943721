import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { catchError, map, of, switchMap, throwError } from 'rxjs';

export const authGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const allowedRole = next.data['allowedRole']
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.isLoggedIn().pipe(
    switchMap(isLoggedIn => {
      console.log('isLoggedIn', isLoggedIn)
      if (!isLoggedIn) {
        return of(router.parseUrl('/login'));
      }

      if (state.url === '/login') {
        return of(router.parseUrl('/booking/list'));
      }

      return authService.getUserInfo().pipe(
        map(user => {
          if (allowedRole.includes(user.role)) {
            return true;
          } else {
            return router.parseUrl('/error?status=403');
          }
        }),
        catchError((error) => {
          console.error('Error occurred in authGuard:', error);
          return throwError(() => 'Failed to pass authGuard.');
        })
      );
    })
  );
}
