import { Injectable } from '@angular/core';
import { Placement, PlacementMap } from '@ims-shared/dto/placements.dto';
import { catchError, map, throwError } from 'rxjs';
import { ApiService } from './api.services';

@Injectable({
  providedIn: 'root'
})
export class PlacementService {

  constructor(
    private apiService: ApiService,
  ) { }

  getPlacements(){
    return this.apiService.get<Placement[]>('/placement').pipe(
      map((res) => {
        const placement: PlacementMap = res.reduce((acc: PlacementMap, placement: Placement)=> {
          let key = placement.format.refName
          if (!acc[key]) {
            acc[key] = [{...placement}];
          }
          else{
            acc[key].push(placement);
          }
          return acc
        }, {});
        return placement;
      }),
      catchError((error) => {
        console.error('Error occurred in getPlacements():', error);
        return throwError(() => 'Failed to get placements refs. Please try again.');
      })
    )
  }
}
