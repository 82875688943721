export enum BookingField {
    SAVE = "save",
    CANCEL_AND_COPY = "cancel & copy",
    SUBMIT = "submit",
    CONFIRM = "confirm",
    APPROVE = "approve",
    ADVERTISER = "advertiser",
    BOOKING_NAME = "booking name",
    BOOKING_CATEGORY = "booking category",
    BOOKING_TYPE = "booking type",
    START_DATE = "start date",
    END_DATE = "end date",
    EXCLUDE_WEEKEND = "exclude weekend",
    EXCLUDE_PUBLIC_HOLIDAY = "exclude public holiday",
    REMARKS = "remarks",
    DETAIL_CHANNEL = "channel",
    DETAIL_DEVICE = "device",
    DETAIL_FORMAT = "format",
    DETAIL_REQUEST_INVENTORY = "request inventory",
    ADD_DETAIL = "add detail",
    DELETE_DETAIL = "delete detail",
    CAMPAIGN_OBJECTIVE = "campaign objective",
    KEY_RESULT_IMPRESSION = "key result impression",
    KEY_RESULT_CTR = "key result ctr",
    KEY_RESULT_OTHER = "key result other",
    ADDITIONAL_INFORMATION = "additional information",
    AUDIENCE_TARGETING = "audience targeting",
    CONTENT_TARGETING = "content targeting",
    KEYWORDS = "keywords"
}