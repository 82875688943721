<mat-form-field appearance="fill">
    <input matInput [matMenuTriggerFor]="menu" [formControl]="tagControl">
    <mat-menu #menu="matMenu" class="content-tag-menu" xPosition="before" yPosition="below">
        <mat-tree [dataSource]="filteredDataSource" [treeControl]="treeControl">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [class]="getTagClass(node)">
                {{node.tag}}
                <mat-icon (click)="$event.stopPropagation(); addContentTag(node)">
                    add
                </mat-icon>
            </mat-tree-node>
            <mat-tree-node *matTreeNodeDef="let node; when: hasChild" (click)="$event.stopPropagation()"
                matTreeNodePadding [class]="getTagClass(node)">
                <div>
                    <mat-icon matTreeNodeToggle>
                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                    {{node.tag}}
                </div>
                <mat-icon (click)="$event.stopPropagation(); addContentTag(node)" *ngIf="node.valueId">add</mat-icon>
            </mat-tree-node>
        </mat-tree>
    </mat-menu>
</mat-form-field>

<div [formGroup]="form" *ngIf="contentTagsArray" class="interest-tag-container">
    <div formArrayName="contentTags">
        <div *ngFor="let control of contentTagsArray.controls; let i = index">
            <mat-card [formGroupName]="i" class="selected-tag-card" color="primary">
                <mat-card-content>
                    <span>{{control.get('tag')?.value}}</span>
                    <mat-icon (click)="removeContentTag(i)" *ngIf="!readonly">close</mat-icon>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>