import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { AuthModule } from './auth/auth.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { ErrorComponent } from './error/error.component';
import { BookingListComponent } from './booking-list/booking-list.component';
import { BookingComponent } from './booking/booking.component';
import { InventoryComponent } from './inventory/inventory.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { NavbarComponent } from './navbar/navbar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { NumberFormatterDirective } from './number-formatter.directive';
import { MatTreeModule } from '@angular/material/tree';
import { BookingModule } from './booking/booking.module';
import { MatDialogModule } from '@angular/material/dialog';
import { NumberUpTo2DecimalDirective } from './number-up-to-2-decimal-formatter.directive';
import { BookingDetailComponent } from './booking-detail/booking-detail.component';
import { BookingDetailModule } from './booking-detail/booking-detail.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { InventoryControlComponent } from './inventory-control/inventory-control.component';
import { TokenAuthHttpInterceptor } from './_service/http-interceptors';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ErrorComponent,
    BookingListComponent,
    BookingComponent,
    InventoryComponent,
    ConfigurationComponent,
    NavbarComponent,
    NumberFormatterDirective,
    NumberUpTo2DecimalDirective,
    BookingDetailComponent,
    InventoryControlComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AuthModule,
    BookingModule,
    BookingDetailModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatButtonModule,
    MatToolbarModule,
    MatMenuModule,
    MatTabsModule,
    MatDividerModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    MatRadioModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatNativeDateModule,
    MatGridListModule,
    HttpClientModule,
    FontAwesomeModule,
    MatPaginatorModule,
    MatTableModule,
    MatTooltipModule,
    MatChipsModule,
    MatTreeModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatSortModule
  ],
  providers: [
    Title,
    {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenAuthHttpInterceptor,
    multi: true,
    },
    { //disable mat btn click ripple effect
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: {
        disabled: true
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
