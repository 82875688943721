import { Component, inject, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AuthService, User } from '../auth/auth.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BookingDetailRecord, CreateBookingDetailDialog } from './create-detail-dialog/create-detail-dialog.component';
import { ConfirmDialog } from './confirm-dialog/confirm-dialog.component';
import { BookingService } from '../_service/booking.service';
import { bookingDateValidation, bookingDetailRecordToDto, CustomErrorStateMatcher, getBookingDetailValidationWarningMessage } from './booking.util';
import { UserRole } from '@ims-shared/enum/user-role';
import { BookingStatus } from '@ims-shared/enum/booking-status';
import { BookingDetailsDto, BookingDto, CreateBookingDto, UpdateBookingDto } from '@ims-shared/dto/booking.dto';
import { SegmentDto } from '@ims-shared/dto/segment.dto';
import { ReferenceDto } from '@ims-shared/dto/reference.dto';
import { RequestType } from '@ims-shared/enum/request-type';
import { combineLatest, forkJoin, map, Observable, of, startWith, switchMap } from 'rxjs';
import { AdvertiserDto } from '@ims-shared/dto/advertiser.dto';
import { formatDate } from 'src/util/dateUtil';
import { isBookingFieldDisabled } from '@ims-shared/utils/permission.util';
import { BookingField } from '@ims-shared/enum/booking-field';
import { PlacementService } from '../_service/placement.service';
import { PlacementMap } from '@ims-shared/dto/placements.dto';
import { AudienceTargetingTableView } from './audience-targeting-table-view/audience-targeting-table-view.component';
import { InventoryService } from '../_service/inventory.service';
import { BookingDetailsRequestInventoryValidationResult } from "@ims-shared/dto/inventory.dto";
import { SwitchPhaseDialog } from './switch-phase-dialog/switch-phase-dialog.component';
import { ContentTagIdTag } from '@ims-shared/dto/content-tag.dto';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {
  user!: User;
  bookingId!: number;
  form: FormGroup = new FormGroup({});
  actionButton!: "confirm" | "approve" | "submit";
  showActionButton: boolean = false;
  showSaveButton: boolean = true;
  today!: Date;
  matcher = new CustomErrorStateMatcher();
  announcer = inject(LiveAnnouncer);
  booking!: BookingDto;
  advertiserOptions: AdvertiserDto[] = [];
  audienceTargetingOptions: SegmentDto[] = [];
  categoryOptions: ReferenceDto[] = [];
  filteredAdvertiserOptions: Observable<AdvertiserDto[]> = new Observable<AdvertiserDto[]>();
  filteredCategoryOptions: Observable<ReferenceDto[]> = new Observable<ReferenceDto[]>();
  filteredAudienceTargetingOptions: Observable<SegmentDto[]> = new Observable<SegmentDto[]>();
  bookingChannelOptions: ReferenceDto[] = [];
  bookingDeviceTypeOptions: ReferenceDto[] = [];
  bookingFormatOptions: ReferenceDto[] = [];
  bookingDetailColumns: string[] = ['format', 'device', 'channel', 'requestedInventory'];
  dataSource = new MatTableDataSource<BookingDetailRecord>([]);
  readonly: boolean = true;
  contentTagDisabled: boolean = false;
  placementMapping: PlacementMap = {};
  isBookingSinglePhase: boolean = true;
  defaultBooking: BookingDto | null = null;
  approveButtonDisabled: boolean = false;

  ngOnInit() {
    this.authService.getUserInfo().subscribe(user => {
      this.user = user;
    });
    this.getBooking();
    this.initializePlacementMapping();
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private bookingService: BookingService,
    private placementService: PlacementService,
    private inventoryService: InventoryService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private location: Location
  ) { }

  get modifiedDate(): string {
    return this.booking?.modifiedDate ? formatDate(new Date(this.booking.modifiedDate), 'YYYY-MM-DD HH:mm') : '';
  }

  get keywordsArray(): FormControl {
    return this.form.get('keywords') as FormControl;
  }

  get audienceTargetings(): FormArray {
    return this.form.get("audienceTargetings") as FormArray;
  }

  getBookingsDataSource(phaseIndex: number): MatTableDataSource<any> {
    const phase = this.form.get('phases')?.value[phaseIndex];
    return new MatTableDataSource(phase.bookings);
  }

  formValue(controlName: string) {
    return this.form.get(controlName)?.value;
  }

  formError(controlName: string, errorName: string) {
    return this.form.get(controlName)?.hasError(errorName);
  }

  initializeForm() {
    const now = new Date();
    this.today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    this.form = new FormGroup({
      advertiser: new FormControl({ value: null, disabled: this.readonly }, [Validators.required]),
      bookingName: new FormControl({ value: null, disabled: this.readonly }, [Validators.required, Validators.maxLength(30)]),
      bookingCategory: new FormControl({ value: null, disabled: this.readonly }, [Validators.required]),
      isTm: new FormControl({ value: true, disabled: this.readonly }, [Validators.required]),
      singlePhase: new FormControl({ value: true, disabled: this.readonly }, [Validators.required]),
      bookingStartDate: new FormControl({ value: this.today, disabled: this.readonly }, [Validators.required]),
      bookingEndDate: new FormControl({ value: this.today, disabled: this.readonly }, [Validators.required]),
      excludeWeekend: new FormControl({ value: false, disabled: this.readonly }),
      excludePublicHoliday: new FormControl({ value: false, disabled: this.readonly }),
      remarks: new FormControl({ value: null, disabled: this.readonly }, [Validators.maxLength(500)]),
      bookingDetails: this.fb.array<BookingDetailRecord[]>([]),
      bookingObjective: new FormControl({ value: null, disabled: this.readonly }),
      keyResultImpressions: new FormControl({ value: null, disabled: this.readonly }),
      keyResultCTR: new FormControl({ value: null, disabled: this.readonly }),
      keyResultOther: new FormControl({ value: null, disabled: this.readonly }),
      additionalInformation: new FormControl({ value: null, disabled: this.readonly }),
      audienceTargetings: this.fb.array([]),
      audienceTargetingInput: new FormControl({ value: "", disabled: this.readonly }),
      contentTags: this.fb.array([]),
      keywords: new FormControl({ value: [], disabled: this.readonly }, [Validators.maxLength(500)]),
      phases: this.fb.array([])
    }, { validators: bookingDateValidation as ValidatorFn })
  }

  initializeAdvertisers(advertisers: AdvertiserDto[]) {
    this.advertiserOptions = advertisers;
    this.observeAdvertiserChange();
  }

  initializeAudienceTargetings(segments: SegmentDto[]) {
    this.audienceTargetingOptions = segments;
    this.observeAudienceTargetingChange();
  }

  initializeReferences(references: ReferenceDto[]) {
    this.categoryOptions = references.filter(reference => reference.refType === 'category');
    this.bookingChannelOptions = references.filter(reference => reference.refType === 'channel');
    this.bookingDeviceTypeOptions = references.filter(reference => reference.refType === 'device');
    this.bookingFormatOptions = references.filter(reference => reference.refType === 'format');

    this.observeCategoryChange();
  }

  getBooking() {
    combineLatest([
      this.route.params,
      this.route.queryParams
    ]).pipe(
      switchMap(([params, queryParams]) => {
        if (!params['id']) {
          this.readonly = false;
        } else {
          this.bookingId = parseInt(params['id']);
          this.readonly = queryParams['readonly'] !== 'false';
        }
        this.defaultBooking = params['defaultBooking'] ? JSON.parse(params['defaultBooking']) : null;
        this.initializeForm();
        return forkJoin({
          references: this.bookingService.findAllReferences(),
          segments: this.bookingService.findSegments(),
          booking: isNaN(this.bookingId) ? of(null) : this.bookingService.findBookingById(this.bookingId)
        });
      })
    ).subscribe({
      next: ({ references, segments, booking }) => {
        this.initializeReferences(references);
        this.initializeAudienceTargetings(segments);
        if (booking) {
          this.booking = booking;
          this.patchBooking(this.booking);
          this.updateBookingFieldStatus();
        } else {
          this.disableTargetSettingWhenCreateBooking();
        }

        if (this.defaultBooking) {
          this.patchBooking(this.defaultBooking);
          this.disableTargetSettingWhenCreateBooking();
        }

        this.loadAdvertisers();
        this.approveButtonDateCheck();
      },
      error: err => {
        console.log(err);
        this.router.navigate(['/error?status=404']);
      }
    })
  }

  initializePlacementMapping() {
    this.placementService.getPlacements().subscribe({
      next: placements => {
        this.placementMapping = placements;
      },
      error: err => {
        console.log(err);
      }
    })
  }

  patchBooking(booking: BookingDto) {
    const phases = this.groupBookingDetailsByPhase(booking.bookingDetails);
    const singlePhase = phases.length === 1;
    const attr = {
      advertiser: this.advertiserOptions.find(option => option.name === booking.advertiser!.name),
      bookingName: booking.bookingName!,
      bookingCategory: this.categoryOptions.find(option => option.refId === booking.bookingCategoryId),
      isTm: booking.isTm!,
      remarks: booking.remarks!,
      bookingObjective: booking.tmBookingObjective!,
      keyResultImpressions: booking.tmKeyResultImpressions!,
      keyResultOther: booking.tmKeyResultOther!,
      keyResultCTR: booking.tmKeyResultCtr!,
      additionalInformation: booking.tmAdditionalInfo!,
      keywords: booking.keywords!,
      singlePhase: singlePhase,
      bookingStartDate: singlePhase ? booking.bookingDetails[0].startDate : null,
      bookingEndDate: singlePhase ? booking.bookingDetails[0].endDate : null,
      excludeWeekend: singlePhase ? booking.bookingDetails[0].excludeWeekend : null,
      excludePublicHoliday: singlePhase ? booking.bookingDetails[0].excludePublicHoliday : null
    }

    this.isBookingSinglePhase = singlePhase;
    this.form.patchValue(attr);
    this.mapBookingDetails(booking.bookingDetails);
    this.mapContentTags(booking.contentTags!);
    this.mapAudienceTargetings(booking);
    this.form.setControl('phases', this.fb.array(phases));
    this.setActionButton(booking);
    this.updateValidators('bookingStartDate', singlePhase ? [Validators.required] : []);
    this.updateValidators('bookingEndDate', singlePhase ? [Validators.required] : []);
  }

  groupBookingDetailsByPhase(bookingDetails: BookingDetailsDto[]) {
    const grouped = bookingDetails.reduce((acc: { [key: number]: BookingDetailsDto[] }, detail: BookingDetailsDto) => {
      if (!acc[detail.phase!]) {
        acc[detail.phase!] = [];
      }
      acc[detail.phase!].push(detail);
      return acc;
    }, {});

    const result = Object.entries(grouped).map(([phase, group]) => {
      const bookingDetails = group.sort((a, b) => {
        const startComparison = a.startDate.localeCompare(b.startDate);
        if (startComparison !== 0) return startComparison;
        return a.endDate.localeCompare(b.endDate);
      });

      return {
        phase: parseInt(phase),
        startDate: bookingDetails[0].startDate,
        endDate: bookingDetails[0].endDate,
        excludeWeekend: bookingDetails[0].excludeWeekend,
        excludePublicHoliday: bookingDetails[0].excludePublicHoliday,
        bookings: bookingDetails.map(detail => {
          return {
            bookingDetailId: detail.bookingDetailId,
            channel: this.bookingChannelOptions.find(option => option.refId === detail.channelId),
            device: this.bookingDeviceTypeOptions.find(option => option.refId === detail.deviceId),
            format: this.bookingFormatOptions.find(option => option.refId === detail.formatId),
            tmRequestInventory: detail.tmRequestInventory,
            startDate: detail.startDate,
            endDate: detail.endDate,
          }
        })
      }
    });
    return result;
  }

  loadAdvertisers() {
    this.bookingService.findAdvertisers().subscribe({
      next: advertisers => {
        this.initializeAdvertisers(advertisers);
        this.patchAdvertiser(this.booking);
        this.patchAdvertiser(this.defaultBooking);
      },
      error: err => {
        console.log('Error loading advertisers:', err);
      }
    });
  }

  patchAdvertiser(booking: BookingDto | null) {
    if (booking) {
      this.form.patchValue({
        advertiser: this.advertiserOptions.find(option => option.name === booking.advertiser?.name)
      });
    }
  }

  updateBookingFieldStatus() {
    const status = this.booking.status!;
    const role = this.user.role;
    this.readonly || isBookingFieldDisabled(status, role, BookingField.ADVERTISER) ? this.form.get('advertiser')?.disable() : this.form.get('advertiser')?.enable();
    this.readonly || isBookingFieldDisabled(status, role, BookingField.BOOKING_NAME) ? this.form.get('bookingName')?.disable() : this.form.get('bookingName')?.enable();
    this.readonly || isBookingFieldDisabled(status, role, BookingField.BOOKING_CATEGORY) ? this.form.get('bookingCategory')?.disable() : this.form.get('bookingCategory')?.enable();
    this.readonly || isBookingFieldDisabled(status, role, BookingField.START_DATE) || this.isBookingApprovedAndStarted() ? this.form.get('bookingStartDate')?.disable() : this.form.get('bookingStartDate')?.enable();
    this.readonly || isBookingFieldDisabled(status, role, BookingField.END_DATE) ? this.form.get('bookingEndDate')?.disable() : this.form.get('bookingEndDate')?.enable();
    this.readonly || isBookingFieldDisabled(status, role, BookingField.EXCLUDE_WEEKEND) ? this.form.get('excludeWeekend')?.disable() : this.form.get('excludeWeekend')?.enable();
    this.readonly || isBookingFieldDisabled(status, role, BookingField.EXCLUDE_PUBLIC_HOLIDAY) ? this.form.get('excludePublicHoliday')?.disable() : this.form.get('excludePublicHoliday')?.enable();
    this.readonly || isBookingFieldDisabled(status, role, BookingField.REMARKS) ? this.form.get('remarks')?.disable() : this.form.get('remarks')?.enable();
    this.readonly || isBookingFieldDisabled(status, role, BookingField.CAMPAIGN_OBJECTIVE) ? this.form.get('bookingObjective')?.disable() : this.form.get('bookingObjective')?.enable();
    this.readonly || isBookingFieldDisabled(status, role, BookingField.KEY_RESULT_IMPRESSION) ? this.form.get('keyResultImpressions')?.disable() : this.form.get('keyResultImpressions')?.enable();
    this.readonly || isBookingFieldDisabled(status, role, BookingField.KEY_RESULT_CTR) ? this.form.get('keyResultCTR')?.disable() : this.form.get('keyResultCTR')?.enable();
    this.readonly || isBookingFieldDisabled(status, role, BookingField.KEY_RESULT_OTHER) ? this.form.get('keyResultOther')?.disable() : this.form.get('keyResultOther')?.enable();
    this.readonly || isBookingFieldDisabled(status, role, BookingField.ADDITIONAL_INFORMATION) ? this.form.get('additionalInformation')?.disable() : this.form.get('additionalInformation')?.enable();
    this.readonly || isBookingFieldDisabled(status, role, BookingField.AUDIENCE_TARGETING) ? this.form.get('audienceTargetingInput')?.disable() : this.form.get('audienceTargetingInput')?.enable();
    this.readonly || isBookingFieldDisabled(status, role, BookingField.CONTENT_TARGETING) ? this.form.get('contentTags')?.disable() : this.form.get('contentTags')?.enable();
    this.readonly || isBookingFieldDisabled(status, role, BookingField.KEYWORDS) ? this.form.get('keywords')?.disable() : this.form.get('keywords')?.enable();
    this.contentTagDisabled = isBookingFieldDisabled(status, role, BookingField.CONTENT_TARGETING) || this.readonly;
    this.showSaveButton = this.readonly ? false : !isBookingFieldDisabled(status, role, BookingField.SAVE);
  }

  disableTargetSettingWhenCreateBooking() {
    this.contentTagDisabled = !this.bookingId;
    this.form.get('audienceTargetingInput')?.disable();
    this.form.get('contentTags')?.disable();
    this.form.get('keywords')?.disable()
  }

  observeAdvertiserChange() {
    this.filteredAdvertiserOptions = this.form.get('advertiser')?.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value.name;
        return name ? this._filterAdvertiserOptions(name as string) : this.advertiserOptions.slice();
      })
    )!
  }

  observeCategoryChange() {
    this.filteredCategoryOptions = this.form.get('bookingCategory')?.valueChanges.pipe(
      startWith(''),
      map(value => {
        const refName = typeof value === 'string' ? value : value.refName;
        return refName ? this._filterCategoryOptions(refName as string) : this.categoryOptions.slice();
      })
    )!
  }

  observeAudienceTargetingChange() {
    this.filteredAudienceTargetingOptions = this.form.get('audienceTargetingInput')?.valueChanges.pipe(
      startWith(''),
      map(value => {
        const displayName = typeof value === 'string' ? value : value.segmentId + "_" + value.segmentName;
        return displayName ? this._filterAudienceTargetingOptions(displayName as string) : this.audienceTargetingOptions.slice()
      })
    )!
    this.form.get("audienceTargetingInput")?.setValue("");
  }

  _filterAdvertiserOptions(value: string): AdvertiserDto[] {
    const filterValue = value.toLowerCase();
    return this.advertiserOptions.filter((option: AdvertiserDto) => {
      return option.name.toLowerCase().includes(filterValue)
    });
  }

  _filterCategoryOptions(value: string): ReferenceDto[] {
    const filterValue = value.toLowerCase();
    return this.categoryOptions.filter(option => option.refName.toLowerCase().includes(filterValue));
  }

  _filterAudienceTargetingOptions(value: string): SegmentDto[] {
    const filterValue = value.toLowerCase();
    return this.audienceTargetingOptions.filter(option => {
      const displayName = option.segmentId + "_" + option.segmentName;
      return displayName.toLowerCase().includes(filterValue);
    })
  }

  displayAdvertiser(advertiser: AdvertiserDto): string {
    return advertiser ? advertiser.name : '';
  }

  displayCategory(category: ReferenceDto): string {
    return category ? category.refName : '';
  }

  displayAudienceTargeting(segment: SegmentDto): string {
    return segment ? segment.segmentId + "_" + segment.segmentName : '';
  }

  advertiserOnBlur() {
    const value = this.formValue('advertiser');
    if (!this.advertiserOptions.includes(value)) {
      this.form.get('advertiser')?.setErrors({ 'advertiserNotFound': true });
    }
  }

  categoryOnBlur() {
    const value = this.formValue('bookingCategory');
    if (!this.categoryOptions.includes(value)) {
      this.form.get('bookingCategory')?.setErrors({ 'categoryNotFound': true });
    }
  }

  mapBookingDetails(bookingDetails: BookingDetailsDto[]) {
    const details = bookingDetails?.map(detail => {
      return {
        bookingDetailId: detail.bookingDetailId,
        channel: this.bookingChannelOptions.find(option => option.refId === detail.channelId),
        device: this.bookingDeviceTypeOptions.find(option => option.refId === detail.deviceId),
        format: this.bookingFormatOptions.find(option => option.refId === detail.formatId),
        tmRequestInventory: detail.tmRequestInventory
      }
    });

    if (details) {
      this.form.setControl('bookingDetails', this.fb.array(details))
      this.dataSource.data = this.formValue('bookingDetails')
    }
  }

  mapContentTags(contentTags: ContentTagIdTag[]) {
    const contentTagsFormGroups = contentTags.map(tag => {
      return this.fb.group({
        id: tag.id,
        tag: tag.tag,
        valueId: tag.valueId
      })
    })

    if (contentTagsFormGroups) {
      this.form.setControl('contentTags', this.fb.array(contentTagsFormGroups));
    }
  }

  mapAudienceTargetings(booking: BookingDto) {
    const audienceTargetings = booking.criteria?.map(criterion => {
      return this.audienceTargetingOptions.find(segment => {
        return segment.criteriaId === criterion.atsCriteriaId && segment.segmentId === criterion.atsSegmentId;
      })
    })
    if (audienceTargetings) {
      this.form.setControl('audienceTargetings', this.fb.array(audienceTargetings));
    }
  }

  setActionButton(booking: BookingDto) {
    if (this.readonly) {
      this.showSaveButton = false;
      return;
    }

    switch (booking.status) {
      case BookingStatus.PENDING_TM_RESPONSE:
        if (!isBookingFieldDisabled(booking.status, this.user.role, BookingField.SUBMIT)) {
          this.setActionButtonText("submit");
        }
        break;
      case BookingStatus.PENDING_TM_RESPONSE_CONFIRMATION:
        if (!isBookingFieldDisabled(booking.status, this.user.role, BookingField.CONFIRM)) {
          this.setActionButtonText("confirm");
        } else {
          this.showActionButton = false;
        }
        break;
      case BookingStatus.PENDING_APPROVAL:
        this.setActionButtonText("approve");
        const canApprove = !isBookingFieldDisabled(booking.status, this.user.role, BookingField.APPROVE);
        const hasRequestedInventory = !this.requestedInventoryIsZero();
        this.showActionButton = canApprove && hasRequestedInventory;
        this.approveButtonDisabled = !(canApprove && hasRequestedInventory);
        console.log("approve button disabled", this.approveButtonDisabled);
        break;
      case BookingStatus.CLOSED:
        this.showSaveButton = false;
        break;
      case BookingStatus.CANCELLED:
        this.showSaveButton = false;
        break;
    }
  }

  setActionButtonText(text: "confirm" | "approve" | "submit") {
    this.showActionButton = true;
    this.actionButton = text;
  }

  switchIsTm(isTm: boolean) {
    if (isTm == this.formValue('isTm')) {
      return;
    }
    const controls = ['bookingObjective', 'keyResultImpressions', 'keyResultCTR', 'KeyResultOther', 'additionalInformation']
    this.form.get('isTm')?.setValue(isTm);

    if (isTm) {
      controls.forEach(control => {
        if (control === 'additionalInformation' || control === 'keyResultOther') {
          this.updateValidators(control, [Validators.maxLength(500)])
        } else {
          this.updateValidators(control, [Validators.required, Validators.maxLength(500)])
        }
      })
    } else {
      controls.forEach(control => {
        this.updateValidators(control, [Validators.maxLength(500)])
      })
    }
  }

  switchPhase(singlePhase: boolean) {
    if (singlePhase == this.formValue('singlePhase')) {
      return;
    }
    if (singlePhase && this.formValue('phases').length > 1) {
      const dialogRef = this.dialog.open(SwitchPhaseDialog, {
        width: '600px',
        height: '300px',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.form.setControl('phases', this.fb.array([]));
          this.form.get('singlePhase')?.setValue(singlePhase);
        }
      });
    } else {
      this.form.get('singlePhase')?.setValue(singlePhase);
    }

  }

  updateValidators(control: string, validators: ValidatorFn[]) {
    this.form.get(control)?.setValidators(validators);
    this.form.get(control)?.updateValueAndValidity();
  }

  addKeyword(event: MatChipInputEvent) {
    const value = event.value.trim();
    let currentKeywords = this.keywordsArray.value;

    const regex = /^[^"'=!+#*~;^()<>[\]]{0,40}$/;
    if (value && regex.test(value)) {
      if (value && !currentKeywords.includes(value)) {
        currentKeywords.push(value);
        this.keywordsArray.setValue(currentKeywords);
        event.chipInput!.clear();
      }
    }
  }

  removeKeyword(index: number) {
    let currentKeywords = this.keywordsArray.value;
    if (index >= 0) {
      currentKeywords.splice(index, 1);
      this.keywordsArray.setValue(currentKeywords);
      this.announcer.announce(`removed keyword`);
    }
  }

  addAudienceTargeting(targeting: SegmentDto) {
    if (!this.audienceTargetings.value.includes(targeting)) {
      this.audienceTargetings.push(this.fb.control(targeting));
    }
  }

  removeAudienceTargeting(index: number) {
    this.audienceTargetings.removeAt(index);
  }

  backPage() {
    this.location.back();
  }

  openBookingDialog() {
    const singlePhase = this.formValue('singlePhase');
    if (this.booking && singlePhase) {
      this.form.setControl('phases', this.fb.array([{
        startDate: this.formValue('bookingStartDate'),
        endDate: this.formValue('bookingEndDate'),
        excludeWeekend: this.formValue('excludeWeekend'),
        excludePublicHoliday: this.formValue('excludePublicHoliday'),
        bookings: this.formValue('phases')[0] ? this.formValue('phases')[0].bookings : [],
        phase: 1
      }]))
    }

    const dialogRef = this.dialog.open(CreateBookingDetailDialog, {
      width: '70%',
      minWidth: '350px',
      maxWidth: '1000px',
      disableClose: true,
      hasBackdrop: true,
      autoFocus: false,
      data: {
        singlePhase: this.formValue('singlePhase'),
        phases: this.formValue("phases"),
        bookingChannelOptions: this.bookingChannelOptions,
        bookingDeviceTypeOptions: this.bookingDeviceTypeOptions,
        bookingFormatOptions: this.bookingFormatOptions,
        bookingDetailColumns: ['delete', ...this.bookingDetailColumns, 'status'],
        isTm: this.formValue('isTm'),
        status: this.booking?.status,
        user: this.user,
        placementMapping: this.placementMapping,
        startDate: this.formValue('bookingStartDate'),
        endDate: this.formValue('bookingEndDate'),
        excludeWeekend: this.formValue('excludeWeekend'),
        excludePublicHoliday: this.formValue('excludePublicHoliday')
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.form.setControl('phases', this.fb.array(result.phases));
        // update approve button status
        if (this.bookingId && this.booking.status === BookingStatus.PENDING_APPROVAL) {
          this.setActionButton(this.booking);
        }
      }
    });
  }

  openConfirmDialog(type: string, warningMessage: string, canSubmit: boolean) {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      width: '600px',
      height: '250px',
      data: {
        type: type,
        bookingId: this.bookingId,
        warningMessage: warningMessage,
        canSubmit: canSubmit
      }
    })
    return dialogRef.afterClosed();
  }

  openAudienceTargetingTableViewDialog() {
    const dialogRef = this.dialog.open(AudienceTargetingTableView, {
      width: "1000px",
      height: "820px",
      data: {
        audienceTargetings: this.formValue('audienceTargetings')?.map((segment: SegmentDto) => segment.segmentName),
        contentTargetings: this.formValue('contentTags')?.map((tag: { id: number, tag: string }) => tag.tag),
        keywords: this.formValue('keywords')
      }
    })
    return dialogRef.afterClosed();
  }

  getRequestType(type: string): RequestType {
    if (!this.bookingId) {
      return RequestType.CREATE;
    }
    const status = this.booking.status;
    if (status === BookingStatus.PENDING_TM_RESPONSE && type == "submit") {
      return RequestType.SUBMIT;
    }
    if (status === BookingStatus.PENDING_TM_RESPONSE_CONFIRMATION && type == "confirm") {
      return RequestType.CONFIRM_TM_RESPONSE;
    }
    if (status === BookingStatus.PENDING_APPROVAL && type == "approve") {
      return RequestType.APPROVE;
    }
    return RequestType.EDIT;
  }

  handleBookingDto(type: string): CreateBookingDto | UpdateBookingDto {
    const singlePhase = this.formValue('singlePhase');
    const dto = {
      requestType: this.getRequestType(type),
      bookingName: this.formValue('bookingName'),
      advertiser: this.formValue('advertiser'),
      bookingCategoryId: this.form.get('bookingCategory')?.value.refId,
      isTm: this.formValue('isTm'),
      remarks: this.formValue('remarks'),
      tmBookingObjective: this.formValue('bookingObjective'),
      tmKeyResultImpressions: this.formValue('keyResultImpressions'),
      tmKeyResultCtr: this.formValue('keyResultCTR'),
      tmKeyResultOther: this.formValue('keyResultOther'),
      tmAdditionalInfo: this.formValue('additionalInformation'),
      criteria: this.formValue('audienceTargetings').map((segment: SegmentDto) => {
        return {
          atsCriteriaId: segment.criteriaId,
          atsSegmentId: segment.segmentId
        }
      }),
      keywords: this.formValue('keywords'),
      contentTags: this.formValue('contentTags'),
      bookingDetails: this.formValue('phases').flatMap((phase: any) => {
        const startDate = singlePhase ? this.formValue('bookingStartDate') : phase.startDate;
        const endDate = singlePhase ? this.formValue('bookingEndDate') : phase.endDate;
        const excludeWeekend = singlePhase ? this.formValue('excludeWeekend') : phase.excludeWeekend;
        const excludePublicHoliday = singlePhase ? this.formValue('excludePublicHoliday') : phase.excludePublicHoliday;

        return phase.bookings.map((booking: any) => {
          return {
            bookingDetailId: booking.bookingDetailId,
            phase: phase.phase,
            channelId: booking.channel.refId,
            deviceId: booking.device.refId,
            formatId: booking.format.refId,
            tmRequestInventory: booking.tmRequestInventory || 0,
            startDate: formatDate(startDate, 'YYYY-MM-DD'),
            endDate: formatDate(endDate, 'YYYY-MM-DD'),
            excludeWeekend: excludeWeekend,
            excludePublicHoliday: excludePublicHoliday
          };
        });
      })
    }
    if (this.bookingId) {
      return { ...dto, id: this.bookingId } as UpdateBookingDto;
    }
    return dto;
  }

  submit(type: string) {
    console.log(this.form.value);
    const bookingDetails: BookingDetailsDto[] = [];

    this.form.value.phases.forEach((phase: any) => {
      const phaseBookingDetails = bookingDetailRecordToDto(phase);
      bookingDetails.push(...phaseBookingDetails);
    });

    this.inventoryService.validateBookingDetailsRequestInventory(bookingDetails).subscribe(
      (results: BookingDetailsRequestInventoryValidationResult[]) => {
        const sufficientTotalInventory = results.every(result => result.sufficientTotalInventory);
        const sufficientDailyInventory = results.every(result => result.sufficientDailyInventory);
        const { canSubmit, warningMessage } = getBookingDetailValidationWarningMessage(
          this.user.role,
          this.formValue('isTm'),
          sufficientDailyInventory,
          sufficientTotalInventory
        );

        this.openConfirmDialog(type, warningMessage, canSubmit).subscribe(confirm => {
          if (confirm) {
            if (this.bookingId) {
              const dto = this.handleBookingDto(type) as UpdateBookingDto;
              this.bookingService.updateBooking(dto).subscribe(id => {
                if (id) {
                  this.router.navigate(['/booking/list']);
                }
              });
            } else {
              const dto = this.handleBookingDto(type) as CreateBookingDto;
              this.bookingService.createBooking(dto).subscribe(id => {
                if (id) {
                  this.router.navigate(['/booking/list']);
                }
              });
            }
          }
        });
      }
    );
  }

  cancelAndCopy() {
    this.openConfirmDialog("cancelAndCopy", '', true).subscribe(confirm => {
      if (confirm) {
        this.bookingService.cancelBooking(this.bookingId).subscribe({
          next: () => {
            const newBooking = {
              ...this.booking,
              bookingId: null,
              status: null,
              bookingDetails: this.booking.bookingDetails.map(detail => {
                return {
                  ...detail,
                  bookingDetailId: null
                }
              }),
            }
            this.router.navigate(['/booking/create', {
              defaultBooking: JSON.stringify(newBooking)
            }])
          },
          error: err => {
            console.log("cancel booking failed");
          }
        })
      }
    })
  }

  saveButtonDisabled(): boolean {
    const phases = this.formValue('phases');
    const bookingDetailsEmpty = phases.length === 0 || this.formValue('phases').some((phase: any) => phase.bookings.length === 0);
    return (this.bookingId && !this.formValue("advertiser")) || !this.form.valid || bookingDetailsEmpty || this.readonly;
  }

  editBookingButtonDisabled(): boolean {
    const startDateControl = this.form.get('bookingStartDate');
    const endDateControl = this.form.get('bookingEndDate');
    let disabled = false;
    if (this.booking && this.booking.status) {
      disabled = isBookingFieldDisabled(this.booking.status!, this.user.role, BookingField.ADD_DETAIL) && isBookingFieldDisabled(this.booking.status!, this.user.role, BookingField.DELETE_DETAIL);
    }

    const isDateInvalid = !startDateControl?.value || !endDateControl?.value || startDateControl?.errors != null || endDateControl?.errors != null;
    return this.formValue("singlePhase") ? isDateInvalid || this.readonly || disabled : this.readonly || disabled;
  }

  bookingTypeTmButtonDisabled(): boolean {
    let disabled = false;
    if (this.booking && this.booking.status) {
      disabled = isBookingFieldDisabled(this.booking.status!, this.user.role, BookingField.BOOKING_TYPE);
    }
    return this.readonly || disabled;
  }

  bookingTypeNonTmButtonDisabled(): boolean {
    let disabled = false;
    if (this.booking && this.booking.status) {
      disabled = isBookingFieldDisabled(this.booking.status!, this.user.role, BookingField.BOOKING_TYPE);
    }
    return this.readonly || disabled;
  }

  singlePhaseButtonDisabled(): boolean {
    return this.readonly || !this.isBookingSinglePhase;
  }

  multiplePhaseButtonDisabled(): boolean {
    return this.readonly;
  }

  removeAudienceTargetingButtonDisabled(): boolean {
    if (this.formValue('audienceTargetingInput')) {
      return false;
    }
    return this.readonly || this.defaultBooking != null;
  }

  isBookingApprovedAndStarted(): boolean {
    const singlePhase = this.formValue('singlePhase');
    if (singlePhase) {
      const startDate = new Date(this.booking.bookingDetails[0].startDate);
      const today = new Date();
      startDate.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);
      return this.booking.status === BookingStatus.APPROVED && startDate < today;
    }
    return false;
  }

  requestedInventoryIsZero(): boolean {
    return this.formValue('phases').some((phase: any) => phase.bookings.some((booking: any) => booking.tmRequestInventory === 0));
  }

  showCancelAndCopyButton(): boolean {
    if (this.readonly) {
      return false;
    }
    return this.booking ? !isBookingFieldDisabled(this.booking.status!, this.user.role, BookingField.CANCEL_AND_COPY) : false;
  }

  approveButtonDateCheck() {
    const format = "YYYY-MM-DD";
    const today = formatDate(new Date(), format);
    const bookingDetails = this.formValue('phases').flatMap((phase: any) => phase.bookings);
    const allDatesValid = bookingDetails.every((booking: BookingDetailsDto) => {
      const startDate = formatDate(new Date(booking.startDate), format);
      return startDate >= today;
    });
    this.approveButtonDisabled = this.approveButtonDisabled || !allDatesValid;
  }
}
