import { Injectable } from "@angular/core";
import { Auth } from '@aws-amplify/auth';
import { AuthOptions } from '@aws-amplify/auth/lib-esm/types';
import { UserRole } from "@ims-shared/enum/user-role";
import { Observable, catchError, from, map, of } from "rxjs";
import { environment } from "src/environments/environment";
import { Router } from '@angular/router';

export const PAGE_ACCESS_PROHABIT_MAP= {
    "Booking": [],
    "Detail": [UserRole.SALES, UserRole.SALES_ADMIN],
    "Inventory": [],
    "Control": [UserRole.SALES, UserRole.SALES_ADMIN],
    "Configuration": [UserRole.TM, UserRole.SALES, UserRole.SALES_ADMIN]
}

export interface User {
    name: string,
    email: string,
    team: string,
    role: UserRole,
    crossDept: boolean
}


type PageLabel = "Booking" | "Detail" | "Inventory" | "Control" | "Configuration";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private router: Router
    ) {
        const authConfig: AuthOptions = {
            userPoolId: environment.userPoolId,
            userPoolWebClientId: environment.userPoolWebClientId,
            region: "ap-southeast-1",
            oauth: {
                domain: environment.cognitoDomain,
                scope: ["openid", "email", "profile"],
                redirectSignIn: `${environment.domain}/auth`,
                redirectSignOut: `${environment.domain}/login`,
                responseType: "code",
            }
        };
        Auth.configure(authConfig);
    }

    public federatedSignIn() {
        Auth.federatedSignIn({
            customProvider: environment.samlIdpName
        });
    }

    public signOut() {
        Auth.signOut();
    }

    public isLoggedIn(): Observable<boolean> {
        return from(Auth.currentSession()).pipe(
            map(session => {
                return session.isValid();
            }),
            catchError(() => of(false))
        )
    }
    public idToken(): Observable<any> {
        return from(Auth.currentSession()).pipe(
          map(session => {
            return session.getIdToken();
          }),
          catchError(error => {
            return of("");
          })
        );
    }

    public getUserInfo(): Observable<User> {
        return from(Auth.currentSession()).pipe(
            map(session => {
                const idToken = session.getIdToken();
                const payload = idToken.payload;
                return {
                    name: payload['name'],
                    email: payload['email'],
                    team: payload['custom:TEAM'],
                    role: payload['custom:ROLE_IMS'],
                    crossDept: payload['custom:CROSS_DEPT']?.toLowerCase() == "true" ? true: false,
                }
            })
        )
    }
}