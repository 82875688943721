export const environment = {
    production: false,
    env: "test",
    samlIdpName: "hketsaml",
    userPoolId: "ap-southeast-1_m00W8bIwK",
    userPoolWebClientId: "753d3vu1jodu476o26umt5pg66",
    cognitoDomain: "tm-ams-test-default.auth.ap-southeast-1.amazoncognito.com",
    domain: "https://ims-test.hket.com",
    apiUrl: "https://ims-test.hket.com/api",
    adSizeList_json: '../assets/data/adSizeList.json',
    adSizePlacementList_json: '../assets/data/adSizePlacementList.json',
    channels_json: '../assets/data/channels.json',
    listing_sample: '../assets/test-data/listing_sample.json',
    inventoryResult_json: '../assets/test-data/inventory_result.json',
    bufferConfig: '../assets/test-data/config.json'
  };
  