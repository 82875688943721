import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    public TARGET_URL = environment.apiUrl;

    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) { }

    get<T>(api: string, header?: HttpHeaders, params?: any, baseUrl: string = this.TARGET_URL): Observable<T> {
        return this.http.get<T>(baseUrl + api, { headers: header, params: params })
    }

    post<T>(api: string, body: any, header?: HttpHeaders, params?: any, responseType?: any): Observable<T> {
        return this.http.post<T>(this.TARGET_URL + api, body, { headers: header, params: params, responseType: responseType})
    }

    put<T>(api: string, body: any, header?: HttpHeaders, params?: any): Observable<T> {
        return this.http.put<T>(this.TARGET_URL + api, body, { headers: header, params: params })
    }

    delete<T>(api: string, header?: HttpHeaders, params?: any): Observable<T> {
        return this.http.delete<T>(this.TARGET_URL + api, { headers: header, params })
    }

    getLocalJSON(url: string): Observable<any> {
        return this.http.get(url);
    }
}