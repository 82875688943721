<div class="container">
    <div class="custom-card">
        <img src="assets/logo.png" alt="Logo">
        <h3>Inventory Management System</h3>

        <ng-container *ngIf="!isLoading">
            <button (click)="login()">
                <mat-icon>cloud_queue</mat-icon>
                <span>AWS login</span>
            </button>
        </ng-container>

        <ng-container *ngIf="isLoading">
            <button>
                <mat-spinner diameter="20" color="accent"></mat-spinner>
            </button>
        </ng-container>

        <span class="msg">*If you encounter login error, please contact <a href="mailto:tm_support@hket.com">ITs</a></span>
    </div>
</div>