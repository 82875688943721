import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";

@Component({
    selector: 'app-audience-targeting-table-view',
    templateUrl: './audience-targeting-table-view.component.html',
    styleUrls: ['../booking.component.css', './audience-targeting-table-view.component.css']
})
export class AudienceTargetingTableView {
    displayedColumns: string[] = ["audienceTargeting", "contentTargeting", "keyword"];
    dataSource: MatTableDataSource<any>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<AudienceTargetingTableView>
    ) {
        this.dataSource = new MatTableDataSource(this.combineArrays(data.audienceTargetings, data.contentTargetings, data.keywords));
        console.log(this.dataSource);
    }

    combineArrays(audienceTargetings: any[], contentTargetings: any[], keywords: string[]) {
        const maxLength = Math.max(audienceTargetings.length, contentTargetings.length, keywords.length);
        const combinedArray = [];

        for (let i = 0; i < maxLength; i++) {
            combinedArray.push({
                audienceTargeting: audienceTargetings[i] || '',
                contentTargeting: contentTargetings[i] || '',
                keyword: keywords[i] || ''
            });
        }
        return combinedArray;
    }
}