import { ReferenceDto } from '@ims-shared/dto/reference.dto';
import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BookingDetailsDailyInquiryDto } from '@ims-shared/dto/booking.dto';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-daily-detail-dialog',
  templateUrl: './daily-detail-dialog.component.html',
  styleUrls: ['./daily-detail-dialog.component.css']
})
export class DailyDetailDialogComponent implements OnInit, OnChanges{

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  
  format!: ReferenceDto
  device!: ReferenceDto
  channel!: ReferenceDto

  constructor(
    public dialogRef: MatDialogRef<DailyDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { dataSource: BookingDetailsDailyInquiryDto[], format: ReferenceDto, device: ReferenceDto, channel: ReferenceDto }
  ) {
    this.dataSource.data = data.dataSource;
    this.format = data.format;
    this.device = data.device;
    this.channel = data.channel;
  }
  
  ngOnInit(): void {
    this.updateTablePaginator()
  }



  displayedColumns: string[]= ['date', 'format', 'device', 'channel', 'dailyBreakdown']; 

  @Input()
  dataSource = new MatTableDataSource<BookingDetailsDailyInquiryDto>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataSource']) {
      this.updateTablePaginator();
    }
  }

  updateTablePaginator() {
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource._updateChangeSubscription();
    }, 0);
  }
}
