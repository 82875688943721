import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subject, takeUntil } from "rxjs";

interface ErrorMessage {
  [key: string]: string;
}

@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.css"]
})
export class ErrorComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  
  errorMessage: ErrorMessage = {
    "403": "You are not authorized to view this page",
    "404": "The page you are looking for does not exist",
    "500": "An error occurred on the server"
  };
  status = "404";
  reason = ""
  isLoggedIn$ = false;

  constructor(
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        console.log(params)
        if (params['status']) {
          this.status = params['status'];
        }
        if (params['reason']) {
          this.reason = params['reason'];
        }
      });
  }

  goBack() {
    window.history.back();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
