import { CollectionViewer, DataSource, SelectionChange } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Injectable } from '@angular/core';
import { ContentTagDto, ContentTagDtoMap } from '@ims-shared/dto/content-tag.dto';
import { BehaviorSubject, firstValueFrom, map, merge, Observable } from 'rxjs';
import { BookingService } from 'src/app/_service/booking.service';

@Injectable({
    providedIn: 'root'
})
export class InterestTagsDropDownService {
    tagMap: Map<number, ContentTagDto> = new Map();

    constructor(private bookingService: BookingService) { }

    async initialData(): Promise<ContentTagDto[]> {
        return firstValueFrom(this.bookingService.findAllLevelInterestTags());
    }

    isExpandable(tag: ContentTagDto) {
        return !tag.is_leaf;
    }
}

export class InterestTagsDataSource implements DataSource<ContentTagDto> {
    dataChange = new BehaviorSubject<ContentTagDto[]>([]);
    nodeChildrenCache: { [key: string]: ContentTagDto[] } = {};

    get data(): ContentTagDto[] {
        return this.dataChange.value;
    }

    set data(value: ContentTagDto[]) {
        this._treeControl.dataNodes = value;
        this.dataChange.next(value);
    }

    constructor(
        private _treeControl: FlatTreeControl<ContentTagDto>,
        private _dropDownService: InterestTagsDropDownService
    ) { }

    connect(collectionViewer: CollectionViewer): Observable<ContentTagDto[]> {
        this._treeControl.expansionModel.changed.subscribe(change => {
            if (
                (change as SelectionChange<ContentTagDto>).added ||
                (change as SelectionChange<ContentTagDto>).removed
            ) {
                this.handleTreeControl(change as SelectionChange<ContentTagDto>);
            }
        });

        return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
    }

    disconnect(collectionViewer: CollectionViewer): void { }

    handleTreeControl(change: SelectionChange<ContentTagDto>) {
        if (change.added) {
            change.added.forEach(node => this.toggleNode(node, true));
        }
        if (change.removed) {
            change.removed
                .slice()
                .reverse()
                .forEach(node => this.toggleNode(node, false));
        }
    }

    toggleNode(node: ContentTagDto, expand: boolean) {
        const index = this.data.indexOf(node);
        if (expand) {
            // Check if the node has children to expand
            if (node.children && node.children.length > 0 && index !== -1) {
                this.data.splice(index + 1, 0, ...node.children);
                this.dataChange.next(this.data);
            }
        } else {
            // Collapse the node by removing its children from the display
            if (index !== -1) {
                let count = 0;
                for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++) { }
                this.data.splice(index + 1, count);
                this.dataChange.next(this.data);
            }
        }
    }
}