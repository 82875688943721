import { Component } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  isLoading: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  login() {
    this.isLoading = true;

    setTimeout(() => {
      this.authService.isLoggedIn().subscribe((isLoggedIn) => {
        if (isLoggedIn) {
          this.isLoading = false;
          this.router.navigate(['/booking/list'])
        } else {
          this.authService.federatedSignIn()
        }
      });
    }, 1000)
  }
}
