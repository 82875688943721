import { Injectable } from "@angular/core";
import { ApiService } from "./api.services";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { catchError, map, Observable, of, throwError } from "rxjs";
import { BookingDto, BookingListDto, BookingListItemDto, BookingNameListItemDto, CreateBookingDto, CreatorListItemDto, PageFilterOptionDto, UpdateBookingDto } from "@ims-shared/dto/booking.dto";
import { SegmentDto } from "@ims-shared/dto/segment.dto";
import { ContentTagDto, ContentTagDtoMap } from "@ims-shared/dto/content-tag.dto";
import { ReferenceDto } from "@ims-shared/dto/reference.dto";
import { AdvertiserDto } from "@ims-shared/dto/advertiser.dto";
import { MatPaginator } from "@angular/material/paginator";
import { InventoryListingFilterProp } from "../booking-list/booking-list.component";
import { Sort } from "@angular/material/sort";

export interface PaginatorDetail{
    pageIndex: number;
    pageSize: number;
    length: number
}

@Injectable({
    providedIn: 'root'
})
export class BookingService {
    headers = new HttpHeaders({
        'Content-Type': 'application/json',
    });

    constructor(private apiService: ApiService) {
        this.loadBookingListingResults();
    }

    listingResult!: BookingListDto
    listingSort!: Sort
    previousListingFilterValue!: InventoryListingFilterProp
    previousListingPaginator!: PaginatorDetail

    private loadBookingListingResults(): void {
        const savedResults = sessionStorage.getItem('bookingListDto');
        if (savedResults) {
            this.listingResult = JSON.parse(savedResults);
        }
        const savedSort = sessionStorage.getItem('bookingListSort');
        if (savedSort) {
            this.listingSort = JSON.parse(savedSort);
        }
        const savedFilterParams = sessionStorage.getItem('listingFilterValue');
        if (savedFilterParams) {
          this.previousListingFilterValue = JSON.parse(savedFilterParams);
        }
        const savedPaginator = sessionStorage.getItem('listingPaginator');
        if (savedPaginator) {
          this.previousListingPaginator = JSON.parse(savedPaginator);
        }
    }

    getSearchResults(): Observable<BookingListDto> {
        return of(this.listingResult);
    }

    getPreviousFilterValue(): Observable<InventoryListingFilterProp> {
        return of(this.previousListingFilterValue);
    }
    
    getPreviousPaginator(): Observable<PaginatorDetail> {
        return of(this.previousListingPaginator);
    }

    getPreviousSort(): Observable<Sort> {
        return of(this.listingSort);
    }

    setSearchResults(results: BookingListDto): void {
        sessionStorage.setItem('bookingListDto', JSON.stringify(results));
        this.listingResult = results;
    }
    
    setPreviousFilter(formData: InventoryListingFilterProp): void {
        sessionStorage.setItem('listingFilterValue', JSON.stringify(formData));
        this.previousListingFilterValue = formData
    }
    
    setPreviousPaginator(paginator: MatPaginator) {
        const detail = { pageIndex: paginator.pageIndex, pageSize: paginator.pageSize, length: paginator.length };
        sessionStorage.setItem('listingPaginator', JSON.stringify(detail));
        this.previousListingPaginator = detail;
    }

    setPreviousSort(sort: Sort): void {
        sessionStorage.setItem('bookingListSort', JSON.stringify(sort));
        this.listingSort = sort
    }

    findAllBookings(offset: number, limit: number, options?: PageFilterOptionDto): Observable<BookingListDto> {
        let params = new HttpParams();
        params = params.set('offset', offset);
        params = params.set('limit', limit);
        if (options) {
            Object.keys(options).forEach(key => {
                const value = options[key as keyof PageFilterOptionDto];
                if (value) {
                    params = params.set(key, value as string);
                }
                else if (value === false){
                    params = params.set(key, value);
                }
            })
        }

        return this.apiService.get<BookingListDto>('/booking', this.headers, params).pipe(
            map(res => {
                return res;
            }),
            catchError((error) => {
                console.error('Error occurred in findAllBookings():', error);
                return throwError(() => 'Failed to load bookings. Please try again.');
            })
        )
    }

    findAllBookingNames(): Observable<BookingNameListItemDto[]>{
        return this.apiService.get<BookingNameListItemDto[]>(`/booking/names`).pipe(
            map(res => {
                return res;
            }),
            catchError((error) => {
                console.error('Error occurred in findAllBookingNames():', error);
                return throwError(() => 'Failed to load booking names. Please try again.');
            })
        )
    }

    findBookingById(id: number): Observable<BookingDto> {
        return this.apiService.get<BookingDto>(`/booking/one/${id}`).pipe(
            map(res => {
                return res;
            }),
            catchError((error) => {
                console.error('Error occurred in findBookingById():', error);
                return throwError(() => 'Failed to load booking. Please try again.');
            })
        )
    }

    findAllReferences(): Observable<ReferenceDto[]> {
        return this.apiService.get<ReferenceDto[]>('/reference').pipe(
            map(res => {
                return res;
            }),
            catchError((error) => {
                console.error('Error occurred in findAllReferences():', error);
                return throwError(() => 'Failed to load references. Please try again.');
            })
        )
    }

    findReferenceByType(type: string): Observable<ReferenceDto[]> {
        return this.apiService.get<ReferenceDto[]>('/reference/' + type).pipe(
            map(res => {
                return res;
            }),
            catchError((error) => {
                console.error('Error occurred in findReferenceByType():', error);
                return throwError(() => 'Failed to load reference. Please try again.');
            })
        )
    }

    findAdvertisers(): Observable<AdvertiserDto[]> {
        return this.apiService.get<AdvertiserDto[]>('/ad-manager/advertisers', this.headers).pipe(
            map(res => {
                return res;
            }),
            catchError((error) => {
                console.error('Error occurred in findAdvertisers():', error);
                return throwError(() => 'Failed to load advertisers. Please try again.');
            })
        )
    }

    findSegments(): Observable<SegmentDto[]> {
        return this.apiService.get<SegmentDto[]>('/booking/segments').pipe(
            map(res => {
                return res;
            }),
            catchError((error) => {
                console.error('Error occurred in findSegments():', error);
                return throwError(() => 'Failed to load segments. Please try again.');
            })
        )
    }

    findCreators(): Observable<CreatorListItemDto[]> {
        return this.apiService.get<CreatorListItemDto[]>('/booking/creators', this.headers).pipe(
            map(res => {
                return res;
            }),
            catchError((error) => {
                console.error('Error occurred in findCreators():', error);
                return throwError(() => 'Failed to load creators. Please try again.');
            })
        )
    }

    findAllLevelInterestTags(): Observable<ContentTagDto[]> {
        return this.apiService.get<ContentTagDto[]>(`/tag`, this.headers, {}).pipe(
            map((res) => {
                return res;
            }),
            catchError((error) => {
                console.error('Error occurred in findInterestTagsByLevel():', error);
                return throwError(() => 'Failed to load InterestTagsLevel. Please try again.');
            })
        );
    }

    createBooking(createBookingDto: CreateBookingDto): Observable<number> {
        return this.apiService.post<number>('/booking', createBookingDto).pipe(
            map(id => {
                return id;
            }),
            catchError((error) => {
                console.error('Error occurred in createBooking():', error);
                return throwError(() => 'Failed to create booking. Please try again.');
            })
        )
    }

    updateBooking(updateBookingDto: UpdateBookingDto): Observable<number> {
        return this.apiService.put<number>('/booking', updateBookingDto).pipe(
            map(id => {
                return id;
            }),
            catchError((error) => {
                console.error('Error occurred in updateBooking():', error);
                return throwError(() => 'Failed to update booking. Please try again.');
            })
        )
    }

    cancelBooking(id: number) {
        return this.apiService.delete(`/booking/${id}`).pipe(
            map(res => {
                return res;
            }),
            catchError((error) => {
                console.error('Error occurred in cancelBooking():', error);
                return throwError(() => 'Failed to cancel booking. Please try again.');
            })
        )
    }
}