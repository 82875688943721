import { ReferenceDto } from '@ims-shared/dto/reference.dto';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config, ConfigBufferPercentageDto, UpdateFreqCapDto, FreqCapMinAndHour, IMSConfigsDto } from '@ims-shared/dto/config.dto';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ApiService } from '../_service/api.services';
import { AdSize } from '@ims-shared/enum/ad-size';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(
    private apiService: ApiService
  ) { }

  header = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  post_header = new HttpHeaders();

  getConfigDetail(){
    return this.apiService.get<IMSConfigsDto>("/config/list", this.header).pipe(
      map((res) => {
        console.log('getConfigDetail:', res);
        return res;
      }),
      catchError(error => {
        if (error.error.message) {
          const serverErrorMessage = error.error.message;
          return throwError(() => serverErrorMessage);
        } else {
          return throwError(() => 'An unexpected error occurred. Please try again.');
        }
      })
    )
  }

  postCreateOrUpdateBufferConfig(body: ConfigBufferPercentageDto): Observable<ConfigBufferPercentageDto>{
    const requestBody = body;
    let params = new HttpParams()
    let api_path = "/config/buffer"
    

    return this.apiService.post<ConfigBufferPercentageDto>(api_path, requestBody, this.post_header, params).pipe(
      map((res) => {
        console.log('postCreateOrUpdateBufferConfig:', res);
        return res;
      }),
      catchError(error => {
        if (error.error.message) {
          const serverErrorMessage = error.error.message;
          return throwError(() => serverErrorMessage);
        } else {
          return throwError(() => 'An unexpected error occurred. Please try again.');
        }
      })
    )
  }

  postCreateOrUpdateFreqCapConfig(body: UpdateFreqCapDto): Observable<UpdateFreqCapDto>{
    const requestBody = body;
    let params = new HttpParams()
    let api_path = "/config/freqcap"
    

    return this.apiService.post<UpdateFreqCapDto>(api_path, requestBody, this.post_header, params).pipe(
      map((res) => {
        console.log('postCreateOrUpdateFreqCapConfig:', res);
        return res;
      }),
      catchError(error => {
        if (error.error.message) {
          const serverErrorMessage = error.error.message;
          return throwError(() => serverErrorMessage);
        } else {
          return throwError(() => 'An unexpected error occurred. Please try again.');
        }
      })
    )
  }

  mapFreqcapValueWithRef(form: UpdateFreqCapDto, formatRefs: ReferenceDto[], deviceRefs: ReferenceDto[]){
    for (const key in form) {
      if (form.hasOwnProperty(key)) {
        const splashRef = formatRefs.filter(formats => formats.refName === AdSize.SPLASH)[0]
        const deviceTypeString = key.replace("freq_", "").replace("_", " ")
        const deviceRef = deviceRefs.filter(devices => devices.refName.toLowerCase() == deviceTypeString)[0]
        const refDetails = {format: splashRef, device: deviceRef}
        form[(key as "freq_app" || "freq_mobile_web" || "freq_desktop_web")] = { ...form[(key as "freq_app" || "freq_mobile_web" || "freq_desktop_web")], ...refDetails };
      }
    }
    console.log("mapFreqcapValueWithRef result: ", form)
    return form
  }
}
