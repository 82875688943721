import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable, switchMap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { CognitoIdToken } from 'amazon-cognito-identity-js'

@Injectable()
export class TokenAuthHttpInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.idToken().pipe(
      switchMap((res: CognitoIdToken | string) => {
        let authId = '';
        if (res !== '') {
          authId = (res as CognitoIdToken).getJwtToken();
        } else {
          authId = res as string;
        }
        const newRequest = req.clone({ setHeaders: { Authorization: 'Bearer ' + authId } });
        return next.handle(newRequest);
      })
    );
  }
}