<div class="confirm-dialog-container">
    <h2 mat-dialog-title>Switch Phase</h2>
    <mat-dialog-content>
        <p>Are you sure to switch phase?</p>
        <p>The booking details will be cleared</p>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-stroked-button mat-dialog-close>Cancel</button>
        <button mat-flat-button color="primary" (click)="confirm()">Confirm</button>
    </mat-dialog-actions>
</div>