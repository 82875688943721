<div class="container">
    <form [formGroup]="form">
        <!-- basic settings -->
        <div class="card">
            <h3 class="card-header">Basic Settings</h3>
            <mat-divider></mat-divider>
            <div class="card-body">
                <div class="form-field-container">
                    <div class="inline-container">
                        <label>Advertiser</label>
                        <mat-spinner diameter="15" strokeWidth="1" *ngIf="advertiserOptions.length == 0"></mat-spinner>
                    </div>
                    <mat-form-field appearance="fill" hideRequiredMarker>
                        <input matInput type="text" formControlName="advertiser" [matAutocomplete]="autoAdvertiser"
                            (focus)="observeAdvertiserChange()" (blur)="advertiserOnBlur()">
                        <mat-autocomplete autoActiveFirstOption requireSelection #autoAdvertiser="matAutocomplete"
                            [displayWith]="displayAdvertiser">
                            <mat-option *ngFor="let option of filteredAdvertiserOptions | async" [value]="option">
                                {{ option.name }}
                            </mat-option>
                        </mat-autocomplete>

                        <mat-error *ngIf="formError('advertiser', 'required')">
                            Advertiser is required
                        </mat-error>

                        <mat-error *ngIf="formError('advertiser', 'advertiserNotFound')">
                            Advertiser not found
                        </mat-error>
                    </mat-form-field>
                </div>


                <div class="form-field-container">
                    <label>Campaign Name</label>
                    <mat-form-field appearance="fill" hideRequiredMarker>
                        <input matInput formControlName="bookingName" [errorStateMatcher]="matcher">

                        <mat-error *ngIf="formError('bookingName', 'maxlength')">
                            Campaign Name should not exceed 30 characters
                        </mat-error>

                        <mat-error *ngIf="formError('bookingName', 'required')">
                            Campaign Name is required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-field-container">
                    <label>Campaign Category</label>
                    <mat-form-field appearance="fill" hideRequiredMarker>
                        <input matInput type="text" formControlName="bookingCategory" [matAutocomplete]="autoCategory"
                            (focus)="observeCategoryChange()" (blur)="categoryOnBlur()">
                        <mat-autocomplete autoActiveFirstOption requireSelection #autoCategory="matAutocomplete"
                            [displayWith]="displayCategory">
                            <mat-option *ngFor="let option of filteredCategoryOptions | async" [value]="option">
                                {{option.refName}}
                            </mat-option>
                        </mat-autocomplete>

                        <mat-error *ngIf="formError('bookingCategory', 'categoryNotFound')">
                            Campaign Category not found
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-field-container">
                    <label>Campaign Type</label>
                    <div class="button-group">
                        <!-- TM Button -->
                        <ng-container *ngIf="formValue('isTm'); else nonTMRaised">
                            <button mat-raised-button color="primary" type="button" disableRipple
                                (click)="switchIsTm(true)" [disabled]="bookingTypeTmButtonDisabled()">TM</button>
                        </ng-container>
                        <ng-template #nonTMRaised>
                            <button mat-stroked-button color="primary" type="button" disableRipple
                                (click)="switchIsTm(true)" [disabled]="bookingTypeTmButtonDisabled()">TM</button>
                        </ng-template>

                        <!-- Non-TM Button -->
                        <ng-container *ngIf="!formValue('isTm'); else nonTMStroked">
                            <button mat-raised-button color="primary" type="button" disableRipple
                                (click)="switchIsTm(false)"
                                [disabled]="bookingTypeNonTmButtonDisabled()">Non-TM</button>
                        </ng-container>
                        <ng-template #nonTMStroked>
                            <button mat-stroked-button color="primary" type="button" disableRipple
                                (click)="switchIsTm(false)"
                                [disabled]="bookingTypeNonTmButtonDisabled()">Non-TM</button>
                        </ng-template>
                    </div>
                </div>

                <div class="form-field-container">
                    <label>Remarks</label>
                    <mat-form-field class="full-width small" appearance="fill">
                        <input matInput formControlName="remarks" [errorStateMatcher]="matcher">
                        <mat-error *ngIf="formError('remarks', 'maxlength')">
                            Remarks should not exceed 500 characters
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-field-container">
                    <label>Campaign Phasing</label>
                    <div class="button-group">
                        <!-- Single Phase Button -->
                        <ng-container *ngIf="formValue('singlePhase'); else multiplePhaseRaised">
                            <button mat-raised-button color="primary" type="button" disableRipple
                                (click)="switchPhase(true)" [disabled]="singlePhaseButtonDisabled()">Single
                                Phase</button>
                        </ng-container>
                        <ng-template #multiplePhaseRaised>
                            <button mat-stroked-button color="primary" type="button" disableRipple
                                (click)="switchPhase(true)" [disabled]="singlePhaseButtonDisabled()">Single
                                Phase</button>
                        </ng-template>

                        <!-- Multiple Phase Button -->
                        <ng-container *ngIf="!formValue('singlePhase'); else multiplePhaseStroked">
                            <button mat-raised-button color="primary" type="button" disableRipple
                                (click)="switchPhase(false)" [disabled]="multiplePhaseButtonDisabled()">Multiple
                                Phase</button>
                        </ng-container>
                        <ng-template #multiplePhaseStroked>
                            <button mat-stroked-button color="primary" type="button" disableRipple
                                (click)="switchPhase(false)" [disabled]="multiplePhaseButtonDisabled()">Multiple
                                Phase</button>
                        </ng-template>
                    </div>
                </div>

                <div class="form-field-container" *ngIf="formValue('singlePhase')">
                    <label>Campaign Date</label>
                    <div class="booking-date-container">
                        <div>
                            <label>From</label>
                            <mat-form-field color="primary">
                                <input matInput [matDatepicker]="bookingStartDatePicker"
                                    formControlName="bookingStartDate" (focus)="bookingStartDatePicker.open()"
                                    [errorStateMatcher]="matcher" [min]="today">

                                <mat-datepicker #bookingStartDatePicker></mat-datepicker>

                                <mat-error *ngIf="formError('bookingStartDate', 'required')">
                                    Campaign Start Date is required
                                </mat-error>

                                <mat-error *ngIf="formError('bookingStartDate', 'invalidDateRange')">
                                    Campaign Start Date should be before Campaign End Date
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div>
                            <label>To</label>
                            <mat-form-field color="primary">
                                <input matInput [matDatepicker]="bookingEndDatePicker" formControlName="bookingEndDate"
                                    (focus)="bookingEndDatePicker.open()" [errorStateMatcher]="matcher" [min]="today">

                                <mat-datepicker #bookingEndDatePicker></mat-datepicker>

                                <mat-error *ngIf="formError('bookingEndDate', 'required')">
                                    Campaign End Date is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <section class="date-picker-checkbox-container">
                        <mat-checkbox disableRipple color="primary" class="date-picker-checkbox"
                            formControlName="excludeWeekend">Exclude
                            Weekend</mat-checkbox>
                        <mat-checkbox disableRipple color="primary" class="date-picker-checkbox"
                            formControlName="excludePublicHoliday">Exclude Public
                            Holiday</mat-checkbox>
                    </section>
                </div>
            </div>
        </div>

        <div class="card">
            <h3 class="card-header">Booking Details</h3>
            <mat-divider></mat-divider>
            <div class="card-body">
                <div *ngFor="let phase of formValue('phases'); let phaseIndex = index" class="phase-container">
                    <div class="booking-details-date-picker-container" *ngIf="!formValue('singlePhase')">
                        <h4 class="phase-text">Phase {{phaseIndex + 1}}</h4>
                        <div class="booking-detail-date-picker">
                            <label class="date-picker-label">From</label>
                            <mat-form-field color="primary">
                                <input matInput [matDatepicker]="startDatePicker" (focus)="startDatePicker.open()"
                                    [value]="phase.startDate" disabled>
                                <mat-datepicker #startDatePicker></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="booking-detail-date-picker">
                            <label class="date-picker-label">To</label>
                            <mat-form-field color="primary">
                                <input matInput [matDatepicker]="endDatePicker" (focus)="endDatePicker.open()"
                                    [value]="phase.endDate" disabled>
                                <mat-datepicker #endDatePicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <section class="date-picker-checkbox-container ml-2" *ngIf="!formValue('singlePhase')">
                        <mat-checkbox disableRipple color="primary" class="date-picker-checkbox"
                            [checked]="phase.excludeWeekend" disabled>Exclude
                            Weekend</mat-checkbox>
                        <mat-checkbox disableRipple color="primary" class="date-picker-checkbox"
                            [checked]="phase.excludePublicHoliday" disabled>Exclude Public
                            Holiday</mat-checkbox>
                    </section>

                    <table mat-table [dataSource]="getBookingsDataSource(phaseIndex)" class="booking-table px-2"
                        *ngIf="getBookingsDataSource(phaseIndex).data.length > 0">

                        <ng-container matColumnDef="format">
                            <th mat-header-cell *matHeaderCellDef class="booking-format-header">Format</th>
                            <td mat-cell *matCellDef="let booking; let i = index" class="booking-format-cell">
                                <mat-form-field appearance="fill" hideRequiredMarker>
                                    <input matInput type="text" class="text-center" disabled
                                        [value]="booking.format.refName">
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="device">
                            <th mat-header-cell *matHeaderCellDef class="booking-device-header">Device</th>
                            <td mat-cell *matCellDef="let booking; let i = index" class="booking-device-cell">
                                <mat-form-field appearance="fill" hideRequiredMarker>
                                    <input matInput type="text" class="text-center" disabled
                                        [value]="booking.device.refName">
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="channel">
                            <th mat-header-cell *matHeaderCellDef class="booking-channel-header">Channel</th>
                            <td mat-cell *matCellDef="let booking; let i = index" class="booking-channel-cell">
                                <mat-form-field appearance="fill" hideRequiredMarker>
                                    <input matInput type="text" class="text-center" disabled
                                        [value]="booking.channel.refName">
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="requestedInventory">
                            <th mat-header-cell *matHeaderCellDef class="booking-requested-inventory-header">Request
                                Inventory</th>
                            <td mat-cell *matCellDef="let booking; let i = index"
                                class="booking-requested-inventory-cell">
                                <mat-form-field appearance="fill" hideRequiredMarker>
                                    <input matInput type="number" class="text-center" appNumberFormatter
                                        [value]="booking.tmRequestInventory" disabled>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef class="booking-status-header">Status</th>
                            <td mat-cell *matCellDef="let booking; let i = index" class="booking-status-cell">
                                <ng-container *ngIf="booking.validationStatus == 'valid'">
                                    <mat-icon class="color-success status-icon">check_circle</mat-icon>
                                </ng-container>
                                <ng-container *ngIf="booking.validationStatus == 'invalid'">
                                    <div class="status-error-container status-icon">
                                        <mat-icon class="color-error">error</mat-icon>
                                        <span class="color-error error-message">{{booking.message}}</span>
                                    </div>
                                </ng-container>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="bookingDetailColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: bookingDetailColumns"
                            [class.invalid-booking]="!row.valid">
                        </tr>
                    </table>
                </div>


                <button mat-flat-button disableRipple color="primary" type="button" (click)="openBookingDialog()"
                    class="booking-add-icon" [disabled]="editBookingButtonDisabled()">
                    Edit
                </button>
            </div>
        </div>

        <!-- targeted marketing details -->
        <div class="card" *ngIf="formValue('isTm')">
            <h3 class="card-header">Targeted Marketing Details</h3>
            <mat-divider></mat-divider>
            <div class="card-body">
                <div>
                    <label>Campaign Objective</label>
                    <p>Should provide OKR format (ref:<a
                            href="https://business.adobe.com/blog/basics/okr-examples">https//business.adobe.com/blog/basics/okr-examples</a>)
                    </p>
                    <div class="form-field-container">
                        <mat-form-field appearance="fill">
                            <input matInput formControlName="bookingObjective" [errorStateMatcher]="matcher">
                            <mat-error *ngIf="formError('bookingObjective', 'required')">
                                Campaign Objective is required
                            </mat-error>

                            <mat-error *ngIf="formError('bookingObjective', 'maxlength')">
                                Campaign Objective should not exceed 500 characters
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="key-result-container">
                    <div class="form-field-container key-result-impression-form-field">
                        <label>Key Result Impressions</label>
                        <mat-form-field appearance="fill">
                            <input matInput formControlName="keyResultImpressions" [errorStateMatcher]="matcher">
                            <mat-error *ngIf="formError('keyResultImpressions', 'required')">
                                Key Result Impressions is required
                            </mat-error>

                            <mat-error *ngIf="formError('keyResultImpressions', 'maxlength')">
                                Key Result Impressions should not exceed 500 characters
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="form-field-container key-result-ctr-form-field">
                        <label>Key Result CTR</label>
                        <mat-form-field appearance="fill">
                            <input matInput formControlName="keyResultCTR" [errorStateMatcher]="matcher">
                            <mat-error *ngIf="formError('keyResultCTR', 'required')">
                                Key Result CTR is required
                            </mat-error>

                            <mat-error *ngIf="formError('keyResultCTR', 'maxlength')">
                                Key Result CTR should not exceed 500 characters
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-field-container">
                    <label>Key Result - Other</label>
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="keyResultOther" [errorStateMatcher]="matcher">
                        <mat-error *ngIf="formError('keyResultOther', 'required')">
                            Key Result - Other is required
                        </mat-error>

                        <mat-error *ngIf="formError('keyResultOther', 'maxlength')">
                            Key Result - Other should not exceed 500 characters
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-field-container">
                    <label>Addition Information (Optional)</label>
                    <mat-form-field appearance="fill">
                        <input matInput formControlName="additionalInformation" [errorStateMatcher]="matcher">
                        <mat-error *ngIf="formError('additionalInformation', 'maxlength')">
                            Additional Information should not exceed 500 characters
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <!-- targeting setting -->
        <div class="card" *ngIf="formValue('isTm')">
            <div class="targeting-setting-header card-header">
                <h3>Targeting Setting</h3>
                <span (click)="openAudienceTargetingTableViewDialog()">Table View</span>
            </div>
            <mat-divider></mat-divider>
            <div class="card-body">
                <div class="form-field-container">
                    <label>Audience Targeting</label>
                    <ng-container *ngIf="audienceTargetings.length > 0">
                        <mat-chip-set>
                            <mat-chip [removable]="true"
                                *ngFor="let targeting of audienceTargetings.value; let i = index">{{targeting.segmentId
                                + '_' + targeting.segmentName}}
                                <mat-icon matChipRemove (click)="removeAudienceTargeting(i)"
                                    *ngIf="!removeAudienceTargetingButtonDisabled()">cancel</mat-icon>
                            </mat-chip>
                        </mat-chip-set>
                    </ng-container>

                    <mat-form-field appearance="fill">
                        <input matInput type="text" formControlName="audienceTargetingInput"
                            [matAutocomplete]="autoAudienceTargeting" (focus)="observeAudienceTargetingChange()">
                        <mat-autocomplete autoActiveFirstOption #autoAudienceTargeting="matAutocomplete"
                            [displayWith]="displayAudienceTargeting">
                            <mat-option *ngFor="let option of filteredAudienceTargetingOptions | async" [value]="option"
                                (click)="addAudienceTargeting(option)">
                                {{option.segmentId + '_' + option.segmentName}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div class="form-field-container">
                    <label>Content Targeting</label>
                    <app-interest-tags [form]="form" [readonly]="contentTagDisabled"></app-interest-tags>
                </div>

                <div class="form-field-container">
                    <label>Keywords</label>
                    <mat-form-field appearance="fill">
                        <mat-chip-grid #chipGrid formControlName="keywords">
                            <mat-chip-row *ngFor="let keyword of keywordsArray.value; let i = index"
                                (removed)="removeKeyword(i)">
                                {{keyword}}
                                <button matChipRemove aria-label="'remove ' + keyword">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip-row>
                        </mat-chip-grid>
                        <input matInput [matChipInputFor]="chipGrid" (matChipInputTokenEnd)="addKeyword($event)"
                            [readonly]="readonly">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <p class="last-update-text" *ngIf="booking">Last Updated: {{modifiedDate}} by {{booking.creatorName}}</p>

        <div class="space-div" *ngIf="!formValue('isTm')"></div>
        <div class="form-buttons">
            <div>
                <button mat-raised-button type="button" disableRipple class="form-back-button"
                    (click)="backPage()">Back</button>
                <button mat-raised-button type="button" disableRipple class="form-cancel-and-copy-button"
                    *ngIf="showCancelAndCopyButton()" (click)="cancelAndCopy()">
                    Cancel & Copy
                </button>
            </div>

            <div>
                <button mat-flat-button color="primary" type="button" disableRipple *ngIf="showActionButton && actionButton !== 'approve'"
                    (click)="submit(actionButton)" class="form-action-button">{{actionButton.charAt(0).toUpperCase() +
                    actionButton.slice(1)}}</button>
                <button mat-flat-button color="primary" type="button" disableRipple *ngIf="showActionButton && actionButton == 'approve'"
                    (click)="submit(actionButton)" class="form-action-button" [disabled]="approveButtonDisabled">{{actionButton.charAt(0).toUpperCase() +
                    actionButton.slice(1)}}</button>
                <button mat-raised-button color="primary" type="submit" disableRipple (click)="submit('save')"
                    [disabled]="saveButtonDisabled()" *ngIf="showSaveButton">
                    {{ bookingId ? 'Save' : 'Submit'}}
                </button>
            </div>

        </div>
    </form>
</div>