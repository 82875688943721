import { Component } from "@angular/core";
import { ConfirmDialog } from "../confirm-dialog/confirm-dialog.component";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'app-switch-phase-dialog',
    templateUrl: './switch-phase-dialog.component.html',
    styleUrls: ['./switch-phase-dialog.component.css']
})
export class SwitchPhaseDialog {
    
    constructor(
        public dialogRef: MatDialogRef<ConfirmDialog>
    ) {}

    confirm() {
        this.dialogRef.close(true);
    }

    cancel() {
        this.dialogRef.close(false);
    }
}